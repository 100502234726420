import axios from "axios"
const TOKEN = `H1pYtsEqFF5QM_dI-2jAAyL45oyZBTE2fg6ALjBQqb12AzFu4yw7tQ==`;
export const api = axios.create({
  withCredentials: false,
  baseURL: "https://rtvfoundation-api.azurewebsites.net",
  headers: {
    "x-functions-key": TOKEN
  }
})

// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})
