import { Link } from "react-router-dom";
import SecondaryFooter from "../SecondaryFooter/SecondaryFooter";
import "./Home.css";

function Home() {
  return (
    <div>
      <div id="content" className="site-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-12 page type-page status-publish ast-article-single"
                id="post-12"
              >
                <header className="entry-header ast-header-without-markup"></header>

                <div className="entry-content clear">
                  <div
                    data-elementor-type="wp-post"
                    data-elementor-id="12"
                    className="elementor elementor-12"
                    data-elementor-settings="[]"
                  >
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <section
                          className="elementor-element elementor-element-8a0de87 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="8a0de87"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-background-overlay"></div>
                          <div className="elementor-container elementor-column-gap-wide">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-2b507b5 elementor-column elementor-col-100 elementor-top-column"
                                data-id="2b507b5"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-990ae94 elementor-widget elementor-widget-spacer"
                                      data-id="990ae94"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-0bdf123 elementor-widget elementor-widget-heading"
                                      data-id="0bdf123"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h3 className="elementor-heading-title elementor-size-default">
                                          You can help the children shape their
                                          future!​
                                        </h3>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-4e0afc4 elementor-widget elementor-widget-divider"
                                      data-id="4e0afc4"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-749c1fd elementor-widget elementor-widget-heading"
                                      data-id="749c1fd"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-heading-title elementor-size-default">
                                          Together, we can build a community
                                          where <b>no one gets left behind</b>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-33b6602 elementor-align-left elementor-mobile-align-left elementor-widget elementor-widget-button"
                                      data-id="33b6602"
                                      data-element_type="widget"
                                      data-widget_type="button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                          <Link
                                            to="/give-a-gift"
                                            className="elementor-button-link elementor-button elementor-size-xs"
                                            role="button"
                                          >
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-button-text">
                                                Support a scholar
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-ce29301 elementor-widget elementor-widget-spacer"
                                      data-id="ce29301"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-e0efb15 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="e0efb15"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-background-overlay"></div>
                          <div className="elementor-container elementor-column-gap-wide">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-ba125b4 elementor-column elementor-col-100 elementor-top-column"
                                data-id="ba125b4"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-ee42ebe elementor-widget elementor-widget-spacer"
                                      data-id="ee42ebe"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-96c594b elementor-widget elementor-widget-heading"
                                      data-id="96c594b"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          OUR ADVOCACY
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-93cd944 elementor-widget elementor-widget-divider"
                                      data-id="93cd944"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-element elementor-element-073b1e1 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="073b1e1"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-no">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-e39c955 elementor-column elementor-col-50 elementor-inner-column"
                                            data-id="e39c955"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-3f2e41b elementor-widget elementor-widget-spacer"
                                                  data-id="3f2e41b"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-809c6fd elementor-widget elementor-widget-text-editor"
                                                  data-id="809c6fd"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        The Roland Torres
                                                        Votacion Foundation,
                                                        Inc., is the heart and{" "}
                                                        <b>
                                                          Corporate Social
                                                          Responsibility
                                                        </b>{" "}
                                                        of <b>Dev Partners</b>{" "}
                                                        in establishing a
                                                        sustainable community.
                                                        Inspired by the
                                                        teachings and doctrines
                                                        of St. John Bosco, the
                                                        RTVFI aims to make a
                                                        difference to the lives
                                                        of the unprivileged
                                                        youth and students
                                                        especially “the lost,
                                                        the least, and the
                                                        last.”
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-4aa8fbc elementor-widget elementor-widget-text-editor"
                                                  data-id="4aa8fbc"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        <span className="focusing">
                                                          Focusing in one of the
                                                          remote barangays of
                                                          Arakan in North
                                                          Cotabato, Island of
                                                          Mindanao, RTFVI
                                                          continues to pursue
                                                          the mission to help
                                                          people — help
                                                          themselves through
                                                          education and to give
                                                          opportunities that
                                                          could help shape and
                                                          build their future.
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-6ad0610 elementor-align-left elementor-widget__width-inherit elementor-mobile-align-center elementor-widget elementor-widget-button"
                                                  data-id="6ad0610"
                                                  data-element_type="widget"
                                                  data-widget_type="button.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-button-wrapper">
                                                      <Link
                                                        to="/about-us"
                                                        className="elementor-button-link elementor-button elementor-size-sm"
                                                        role="button"
                                                      >
                                                        <span className="elementor-button-content-wrapper">
                                                          <span className="elementor-button-text">
                                                            learn more
                                                          </span>
                                                        </span>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-5679d39 elementor-widget elementor-widget-spacer"
                                                  data-id="5679d39"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-2775c09 elementor-column elementor-col-50 elementor-inner-column"
                                            data-id="2775c09"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-background-overlay"></div>
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-e9b6963 elementor-widget elementor-widget-spacer"
                                                  data-id="e9b6963"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <div
                                      className="elementor-element elementor-element-f6ae640 elementor-widget elementor-widget-spacer"
                                      data-id="f6ae640"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-4db683b elementor-widget elementor-widget-spacer"
                                      data-id="4db683b"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-b54dc42 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="b54dc42"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-0ae9308 elementor-column elementor-col-100 elementor-top-column"
                                data-id="0ae9308"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-4c84c04 elementor-widget elementor-widget-spacer"
                                      data-id="4c84c04"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-794d25c elementor-widget elementor-widget-heading"
                                      data-id="794d25c"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          OUR PROGRAMS
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-04147ef elementor-widget elementor-widget-divider"
                                      data-id="04147ef"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-6db32f7 elementor-widget elementor-widget-text-editor"
                                      data-id="6db32f7"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <p>
                                            RTVFI believes that <b>EDUCATION</b>{" "}
                                            is the most powerful tool you can
                                            use to change the world. The
                                            foundation provides Pre-school to
                                            College Scholarships, as well as
                                            Technical-Vocational scholarship
                                            grants to help the youth build and
                                            reach their dreams.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-element elementor-element-c9aa1ee elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="c9aa1ee"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-3e20c0e elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="3e20c0e"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-5d91b33 elementor-widget elementor-widget-heading"
                                                  data-id="5d91b33"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">
                                                      01.
                                                    </h5>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-d227613 elementor-widget elementor-widget-heading"
                                                  data-id="d227613"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                      — Pre-school to College
                                                      Scholarships​
                                                    </h2>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-bae3443 elementor-widget elementor-widget-text-editor"
                                                  data-id="bae3443"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        The foundation grants
                                                        scholarships and
                                                        financial assistance to
                                                        the qualified
                                                        underprivileged students
                                                        from all levels — from
                                                        Pre-school, Elementary,
                                                        Junior High School,
                                                        Senior High School and
                                                        to College level.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-9193645 elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="9193645"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-a4af25b elementor-widget elementor-widget-heading"
                                                  data-id="a4af25b"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">
                                                      02.
                                                    </h5>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-65b1a0a elementor-widget elementor-widget-heading"
                                                  data-id="65b1a0a"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                      — Technical-Vocational
                                                      Scholarship Program​
                                                    </h2>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-d2165e2 elementor-widget elementor-widget-text-editor"
                                                  data-id="d2165e2"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        We extend our reach by
                                                        offering alternative
                                                        scholarships to the
                                                        out-of-school youth who
                                                        would like to gain an
                                                        industry-based skills
                                                        through Dev Partners
                                                        Academy, a technical
                                                        school owned and
                                                        operated by the
                                                        Foundation.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-38d9d97 elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="38d9d97"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-5f1fcb7 elementor-widget elementor-widget-heading"
                                                  data-id="5f1fcb7"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">
                                                      03.
                                                    </h5>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-a4e9278 elementor-widget elementor-widget-heading"
                                                  data-id="a4e9278"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                      — Internship Programs
                                                    </h2>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-98f80ec elementor-widget elementor-widget-text-editor"
                                                  data-id="98f80ec"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        The foundation also
                                                        provides college
                                                        scholars an Internship
                                                        Program with technical
                                                        and industrial working
                                                        experience to give them
                                                        better chances of
                                                        landing a suitable job
                                                        at Dev Partners.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <div
                                      className="elementor-element elementor-element-43bbb91 elementor-widget elementor-widget-spacer"
                                      data-id="43bbb91"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-a0668b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="a0668b2"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-dda722c elementor-column elementor-col-100 elementor-top-column"
                                data-id="dda722c"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-2c5d2e1 elementor-widget elementor-widget-spacer"
                                      data-id="2c5d2e1"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-element elementor-element-27facfa elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="27facfa"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-d3d6c68 elementor-column elementor-col-100 elementor-inner-column"
                                            data-id="d3d6c68"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-a82b062 elementor-widget elementor-widget-text-editor"
                                                  data-id="a82b062"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        “We believe that when
                                                        one family member
                                                        graduates from college,
                                                        that scholar can help
                                                        another sibling go to
                                                        school and serve as the
                                                        key to lift his/her
                                                        family out of poverty.
                                                        Through RTFVI
                                                        Scholarship program, we
                                                        help the youth and
                                                        students reach their
                                                        full potential and
                                                        achieve their dreams.”
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-94cf576 elementor-widget elementor-widget-heading"
                                                  data-id="94cf576"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                      OUR SCHOLARS
                                                    </h2>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <div
                                      className="elementor-element elementor-element-8fa1666 elementor-widget elementor-widget-divider"
                                      data-id="8fa1666"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-cfde0bc elementor-widget elementor-widget-text-editor"
                                      data-id="cfde0bc"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <p>
                                            The Roland Torres Votacion
                                            Foundation, Inc. aims to provide
                                            full<b> </b>scholarships<b> </b>to
                                            1,000 students in 2025.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-element elementor-element-565377b elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="565377b"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-47d220a elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="47d220a"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-282843e elementor-widget elementor-widget-heading"
                                                  data-id="282843e"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-xxl">
                                                      193
                                                    </h2>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-cee4365 elementor-widget elementor-widget-text-editor"
                                                  data-id="cee4365"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>Elementary</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-d24e70e elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="d24e70e"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-2c6151d elementor-widget elementor-widget-heading"
                                                  data-id="2c6151d"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-xxl">
                                                      67
                                                    </h2>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-4a59c36 elementor-widget elementor-widget-text-editor"
                                                  data-id="4a59c36"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>High School</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-fca0b31 elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="fca0b31"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-97e3ed5 elementor-widget elementor-widget-heading"
                                                  data-id="97e3ed5"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-xxl">
                                                      5
                                                    </h2>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-2ac5d08 elementor-widget elementor-widget-text-editor"
                                                  data-id="2ac5d08"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>College</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <div
                                      className="elementor-element elementor-element-afd5a20 elementor-widget elementor-widget-spacer"
                                      data-id="afd5a20"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-7b90a1f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="7b90a1f"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-552ca15 elementor-column elementor-col-100 elementor-top-column"
                                data-id="552ca15"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-0bbc0e4 elementor-widget elementor-widget-spacer"
                                      data-id="0bbc0e4"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-e5c05aa elementor-widget elementor-widget-text-editor"
                                      data-id="e5c05aa"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <h3 className="header-bold">
                                            <b>
                                              “YOU CAN HELP US MAKE A
                                              DIFFERENCE…
                                            </b>
                                          </h3>
                                          <p className="description header-paragraph">
                                            for someone who’s fighting for their
                                            dreams today.”
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-element elementor-element-07fa348 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="07fa348"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-f62b4d0 donate-hover  elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="f62b4d0"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-c538c88 donate-hover  elementor-widget elementor-widget-text-editor"
                                                  data-id="c538c88"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <Link to="/donate">
                                                        <div className="donate-link">
                                                          Donate
                                                        </div>
                                                        <div className="div-500">
                                                          <p className="donate-price">
                                                            ₱500
                                                          </p>

                                                          <h6 className="annually">
                                                            annually
                                                          </h6>
                                                        </div>
                                                        <p className="to-support">
                                                          to support the school
                                                          bags and supplies of
                                                          preschool to high
                                                          school students.
                                                        </p>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-b7623b9 donate-hover  elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="b7623b9"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-df48c5b donate-hover  elementor-widget elementor-widget-text-editor"
                                                  data-id="df48c5b"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <Link to="/donate">
                                                        <div className="donate-link">
                                                          Donate
                                                        </div>
                                                        <div className="div-500">
                                                          <p className="donate-price">
                                                            ₱700
                                                          </p>

                                                          <h6 className="annually">
                                                            annually
                                                          </h6>
                                                        </div>
                                                        <p className="to-support">
                                                          to support the school
                                                          fees of preschool to
                                                          high school students.
                                                        </p>
                                                      </Link>{" "}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-3f39e85 donate-hover  elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="3f39e85"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-ef55c26 donate-hover  elementor-widget elementor-widget-text-editor"
                                                  data-id="ef55c26"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <Link to="/donate">
                                                        <div className="donate-link">
                                                          Donate
                                                        </div>
                                                        <div className="div-500">
                                                          <p className="donate-price">
                                                            ₱1000
                                                          </p>

                                                          <h6 className="annually">
                                                            monthly
                                                          </h6>
                                                        </div>
                                                        <p className="to-support">
                                                          to help college
                                                          students get their
                                                          diploma.
                                                        </p>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <div
                                      className="elementor-element elementor-element-e8a38b7 elementor-widget elementor-widget-spacer"
                                      data-id="e8a38b7"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-e82a2a7 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="e82a2a7"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-178f7da elementor-column elementor-col-100 elementor-top-column"
                                data-id="178f7da"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-53fcf80 elementor-widget elementor-widget-spacer"
                                      data-id="53fcf80"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-288c6a4 elementor-widget elementor-widget-heading"
                                      data-id="288c6a4"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          OUR GREAT SPONSORS
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-d1957ce elementor-widget elementor-widget-divider"
                                      data-id="d1957ce"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-5e565cd elementor-widget elementor-widget-spacer"
                                      data-id="5e565cd"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-b2c9164 elementor-arrows-position-inside elementor-widget elementor-widget-image-carousel"
                                      data-id="b2c9164"
                                      data-element_type="widget"
                                      data-settings='{"slides_to_show":"3","slides_to_scroll":"3","navigation":"arrows","autoplay":"yes","pause_on_hover":"yes","pause_on_interaction":"yes","autoplay_speed":5000,"infinite":"yes","speed":500,"direction":"ltr"}'
                                      data-widget_type="image-carousel.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          className="elementor-image-carousel-wrapper swiper-container"
                                          dir="ltr"
                                        >
                                          <div className="elementor-image-carousel swiper-wrapper">
                                            <div className="swiper-slide">
                                              <figure className="swiper-slide-inner">
                                                <img
                                                  className="swiper-slide-image"
                                                  src="assets/images/devPartners.png"
                                                  alt="devPartners"
                                                />
                                              </figure>
                                            </div>
                                            <div className="swiper-slide">
                                              <figure className="swiper-slide-inner">
                                                <img
                                                  className="swiper-slide-image"
                                                  src="assets/images/devHub.png"
                                                  alt="devHub"
                                                />
                                              </figure>
                                            </div>
                                            <div className="swiper-slide">
                                              <figure className="swiper-slide-inner">
                                                <img
                                                  className="swiper-slide-image"
                                                  src="assets/images/alliswell.png"
                                                  alt="alliswell"
                                                />
                                              </figure>
                                            </div>
                                          </div>
                                          <div className="elementor-swiper-button elementor-swiper-button-prev">
                                            <i
                                              className="eicon-chevron-left"
                                              aria-hidden="true"
                                            ></i>
                                            <span className="elementor-screen-only">
                                              Previous
                                            </span>
                                          </div>
                                          <div className="elementor-swiper-button elementor-swiper-button-next">
                                            <i
                                              className="eicon-chevron-right"
                                              aria-hidden="true"
                                            ></i>
                                            <span className="elementor-screen-only">
                                              Next
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-16ee1dd elementor-widget elementor-widget-spacer"
                                      data-id="16ee1dd"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-10cb21c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="10cb21c"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-fcf83b6 elementor-column elementor-col-100 elementor-top-column"
                                data-id="fcf83b6"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-4ad6d04 elementor-widget elementor-widget-spacer"
                                      data-id="4ad6d04"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-353a7d7 elementor-widget elementor-widget-heading"
                                      data-id="353a7d7"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          news & events
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-09bcad9 elementor-widget elementor-widget-divider"
                                      data-id="09bcad9"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-6c9b42c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="6c9b42c"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-ec5c14b elementor-column elementor-col-100 elementor-top-column"
                                data-id="ec5c14b"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-7c40b3e elementor-grid-eael-col-3 elementor-grid-tablet-eael-col-2 elementor-grid-mobile-eael-col-1 elementor-widget elementor-widget-eael-post-grid"
                                      data-id="7c40b3e"
                                      data-element_type="widget"
                                      data-settings='{"eael_post_grid_columns":"eael-col-3","eael_post_grid_columns_tablet":"eael-col-2","eael_post_grid_columns_mobile":"eael-col-1"}'
                                      data-widget_type="eael-post-grid.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          id="eael-post-grid-7c40b3e"
                                          className="eael-post-grid-container"
                                        >
                                          <div
                                            className="eael-post-grid eael-post-appender eael-post-appender-7c40b3e eael-post-grid-style-default"
                                            data-layout-mode="masonry"
                                          >
                                            <article
                                              className="eael-grid-post eael-post-grid-column"
                                              data-id="1779"
                                            >
                                              <div className="eael-grid-post-holder">
                                                <div className="eael-grid-post-holder-inner">
                                                  <div className="eael-entry-media">
                                                    <div className="eael-entry-overlay fade-in">
                                                        <i
                                                          className="fab fa-readme"
                                                          aria-hidden="true"
                                                        ></i>
                                                      <Link to="/education-in-this-new-normal-time-rtvfi-cares">
                                                      </Link>
                                                    </div>
                                                    <div className="eael-entry-thumbnail">
                                                      <img
                                                        src="assets/images/RTV-Supplies.jpg"
                                                        alt=""
                                                      ></img>
                                                    </div>
                                                  </div>
                                                  <div className="eael-entry-wrapper">
                                                    <header className="eael-entry-header">
                                                      <h2 className="eael-entry-title">
                                                        <Link
                                                          className="eael-grid-post-link"
                                                          to="/education-in-this-new-normal-time-rtvfi-cares"
                                                          title="Education in This &#8220;new normal&#8221; time: RTVfi cares"
                                                        >
                                                          Education in This
                                                          &#8220;new
                                                          normal&#8221; time:
                                                          RTVfi cares
                                                        </Link>
                                                      </h2>
                                                    </header>
                                                    <div className="eael-entry-content">
                                                      <div className="eael-grid-post-excerpt">
                                                        <p>
                                                          The onset of the
                                                          Covid-19 pandemic has
                                                          forced everyone to
                                                          adapt and adopt new
                                                          ways of living. The
                                                          lockdowns,
                                                          quarantines,...
                                                        </p>
                                                        <Link
                                                          to="/education-in-this-new-normal-time-rtvfi-cares"
                                                          className="eael-post-elements-readmore-btn"
                                                        >
                                                          Read More
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </article>
                                            <article
                                              className="eael-grid-post eael-post-grid-column"
                                              data-id="1715"
                                            >
                                              <div className="eael-grid-post-holder">
                                                <div className="eael-grid-post-holder-inner">
                                                  <div className="eael-entry-wrapper">
                                                    <header className="eael-entry-header">
                                                      <h2 className="eael-entry-title">
                                                        <Link
                                                          className="eael-grid-post-link"
                                                          to="/moving-ahead-with-purpose"
                                                          title="Moving  Ahead   with   Purpose"
                                                        >
                                                          Moving Ahead with
                                                          Purpose
                                                        </Link>
                                                      </h2>
                                                    </header>
                                                    <div className="eael-entry-content">
                                                      <div className="eael-grid-post-excerpt">
                                                        <p>
                                                          MOVING AHEAD WITH
                                                          PURPOSE: RTVFI 1st
                                                          General Assembly for
                                                          2020 The Roland Torres
                                                          Votacion Foundation
                                                          Inc. held its 1st
                                                          General...
                                                        </p>
                                                        <Link
                                                          to="/moving-ahead-with-purpose"
                                                          className="eael-post-elements-readmore-btn"
                                                        >
                                                          Read More
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </article>
                                            <article
                                              className="eael-grid-post eael-post-grid-column"
                                              data-id="1411"
                                            >
                                              <div className="eael-grid-post-holder">
                                                <div className="eael-grid-post-holder-inner">
                                                  <div className="eael-entry-wrapper">
                                                    <header className="eael-entry-header">
                                                      <h2 className="eael-entry-title">
                                                        <Link
                                                          className="eael-grid-post-link"
                                                          to="/school-bags-giving"
                                                          title="SCHOOL BAGS GIVING"
                                                        >
                                                          SCHOOL BAGS GIVING
                                                        </Link>
                                                      </h2>
                                                    </header>
                                                    <div className="eael-entry-content">
                                                      <div className="eael-grid-post-excerpt">
                                                        <p>
                                                          School bags and other
                                                          equipment to support
                                                          schooling have become
                                                          a very essential need
                                                          for children attending
                                                          school. School bags...
                                                        </p>
                                                        <Link
                                                          to="/school-bags-giving"
                                                          className="eael-post-elements-readmore-btn"
                                                        >
                                                          Read More
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </article>
                                          </div>
                                          <div className="clearfix"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-1870dc5 elementor-align-center elementor-widget elementor-widget-button"
                                      data-id="1870dc5"
                                      data-element_type="widget"
                                      data-widget_type="button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                          <Link
                                            to="/news-events"
                                            className="elementor-button-link elementor-button elementor-size-md"
                                            role="button"
                                          >
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-button-text">
                                                Read more
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-00ef1ed elementor-widget elementor-widget-spacer"
                                      data-id="00ef1ed"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <SecondaryFooter></SecondaryFooter>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
