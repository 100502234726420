import "./SuppliesGiving.css";
import { NavLink } from "react-router-dom";

function SuppliesGiving() {
  return (
    <div>
      <div id="content" className="site-content site-card-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-1715 post type-post status-publish format-standard hentry category-uncategorized ast-article-single"
                id="post-1715"
              >
                <div className="ast-post-format- ast-no-thumb single-layout-1">
                  <header className="entry-header ast-no-thumbnail">
                    <div className="ast-single-post-order">
                      <h1 className="entry-title" itemProp="headline">
                        SCHOOL SUPPLIES GIVING
                      </h1>
                      <div className="entry-meta">
                        <span className="posted-on">
                          <span className="published" itemProp="datePublished">
                            {" "}
                            February 3, 2020
                          </span>
                          <span className="updated" itemProp="dateModified">
                            {" "}
                            February 3, 2020
                          </span>
                        </span>{" "}
                        / By admin
                      </div>{" "}
                    </div>
                  </header>

                  <div className="entry-content clear" itemProp="text">
                    <p>
                      More than 1 million kids live in extreme poverty in the
                      Philippines and arrive on the first day of school without
                      the supplies they need to learn. Being equipped with the
                      proper tools for school is essential to academic success.
                      Every year, the Roland Torres Votacion Foundation, Inc.,
                      give hundreds of bags – the number of items that we donate
                      is also similar to the number of scholars of foundation
                      today. The foundation also aims to help scholars by giving
                      them the tools they need to succeed and to proceed. By
                      providing them with proper tools and necessary school
                      supplies for their classrooms and studies, scholars don’t
                      have to spend money from their own pockets. We also
                      identify some of the crucial needs like pens, pencils, and
                      shoes, etc. and hand over to them so that they can be well
                      equipped for the next academic years.
                    </p>
                    <p>
                      If you wish to donate new school supplies, you can deliver
                      supplies at DEV HUB. Your gift will make a differences!
                    </p>
                  </div>
                </div>
              </article>

              <nav
                className="navigation post-navigation"
                role="navigation"
                aria-label="Posts"
              >
                <h2 className="screen-reader-text">Post navigation</h2>
                <div className="nav-links">
                  <div className="nav-previous">

                  </div>
                  <div className="nav-next">
                    <NavLink to="/school-bags-giving" rel="next">
                      Next Post <span className="ast-right-arrow">→</span>
                    </NavLink>
                  </div>
                </div>
              </nav>
              <div id="comments" className="comments-area"></div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuppliesGiving;
