import "./NothingHere.css";

function NothingHere() {
  return (
    <div>
      <div id="content" className="site-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <section className="error-404 not-found">
              <div className="ast-404-layout-1">
                <header className="page-header">
                  <h1 className="page-title">
                    This page doesn&#039;t seem to exist.
                  </h1>
                </header>
                <div className="page-content">
                  <div className="page-sub-title">
                    It looks like the link pointing here was faulty.
                  </div>

                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NothingHere;
