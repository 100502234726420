import { Link, NavLink } from 'react-router-dom';
import './Header.css';

const small = 'assets/images/cropped-logoname-2-308x51.png';
const xsmall = 'assets/images/cropped-logoname-2-308x50.png';
const medium = 'assets/images/cropped-logoname-2.png';

function Header() {
  return (
    <div>
      <header
        className='site-header header-main-layout-1 ast-primary-menu-enabled ast-menu-toggle-icon ast-mobile-header-inline'
        id='masthead'
      >
        <div className='main-header-bar-wrap'>
          <div className='main-header-bar'>
            <div className='ast-container'>
              <div className='ast-flex main-header-container'>
                <div className='site-branding'>
                  <div className='ast-site-identity'>
                    <span className='site-logo-img'>
                      <Link to='/' className='custom-logo-link' rel='home'>
                        <img
                          width='308'
                          height='51'
                          src={'assets/images/cropped-logoname-2-308x51.png'}
                          className='custom-logo'
                          alt='RTV Foundation'
                          srcSet={`${small} 300w, ${xsmall} 300w, ${medium} 600w`}
                          sizes='(max-width: 308px) 100vw, 308px'
                        />
                      </Link>
                    </span>
                  </div>
                </div>

                <div className='ast-mobile-menu-buttons'>
                  <div className='ast-button-wrap'>
                    <button
                      type='button'
                      className='menu-toggle main-header-menu-toggle  ast-mobile-menu-buttons-fill '
                      aria-controls='primary-menu'
                      aria-expanded='false'
                    >
                      <span className='screen-reader-text'>Main Menu</span>
                      <span className='menu-toggle-icon'></span>
                    </button>
                  </div>
                </div>
                <div className='ast-main-header-bar-alignment'>
                  <div className='main-header-bar-navigation'>
                    <nav
                      className='ast-flex-grow-1 navigation-accessibility'
                      id='site-navigation'
                      aria-label='Site Navigation'
                    >
                      <div className='main-navigation'>
                        <ul
                          id='primary-menu'
                          className='main-header-menu ast-nav-menu ast-flex ast-justify-content-flex-end  submenu-with-border'
                        >
                          <li
                            id='menu-item-21'
                            className='menu-item menu-item-type-post_type menu-item-object-page menu-item-home page_item page-item-12 menu-item-21'
                          >
                            <NavLink to='/'>HOME</NavLink>
                          </li>
                          <li
                            id='menu-item-1104'
                            className='menu-item menu-item-type-post_type menu-item-object-page menu-item-1104'
                          >
                            <NavLink to='about-us'>ABOUT US</NavLink>
                          </li>
                          <li
                            id='menu-item-1103'
                            className='menu-item menu-item-type-post_type menu-item-object-page menu-item-1103'
                          >
                            <NavLink to='support-us'>SUPPORT US​</NavLink>
                          </li>
                          <li
                            id='menu-item-1102'
                            className='menu-item menu-item-type-post_type menu-item-object-page menu-item-1102'
                          >
                            <NavLink to='give-a-gift'>GIVE A GIFT</NavLink>
                          </li>
                          <li
                            id='menu-item-133'
                            className='menu-item menu-item-type-post_type menu-item-object-page menu-item-133'
                          >
                            <NavLink to='donate'>DONATE</NavLink>
                          </li>
                          <li
                            id='menu-item-1101'
                            className='menu-item menu-item-type-post_type menu-item-object-page menu-item-1101'
                          >
                            <NavLink to='news-events'>
                              NEWS &#038; EVENTS
                            </NavLink>
                          </li>
                          <li
                            id='menu-item-17'
                            className='menu-item menu-item-type-post_type menu-item-object-page menu-item-17'
                          >
                            <NavLink to='contact-us'>CONTACT US</NavLink>
                          </li>
                          <li
                            id='menu-item-1147'
                            className='menu-item menu-item-type-post_type menu-item-object-page menu-item-1147'
                          >
                            <NavLink to='cart'>
                              <i
                                className='fa fa-shopping-cart'
                                aria-hidden='true'
                              ></i>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
