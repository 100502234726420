// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import CheckoutForm from "../CheckoutForm/CheckoutForm";
import "./Checkout.css";

function Checkout() {
  const cartItems = JSON.parse(localStorage.getItem(`cartItems`) || `[]`);
  const [cart] = useState(cartItems);
  const [cartTotal, setCartTotal] = useState(0);

  useEffect(() => {
    let theTotal = 0;
    cart.map((item) => {
      theTotal = theTotal + item.orders * item.item.price;
      return item;
    });
    setCartTotal(theTotal);
  }, [cart]);

  return (
    <div>
      <div id="content" className="site-content">
        <div className="ast-container ast-container-checkout">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-1125 page type-page status-publish ast-article-single"
                id="post-1125"
              >
                <header className="entry-header ast-header-without-markup"></header>

                <div className="entry-content clear">
                  <div
                    style={{ height: "30px" }}
                    aria-hidden="true"
                    className="wp-block-spacer"
                  ></div>

                  <h2 className="has-text-align-center">
                    THANK YOU FOR YOUR SUPPORT
                  </h2>

                  <div className="woocommerce">
                    <div className="woocommerce-notices-wrapper"></div>
                    <div className="woocommerce-notices-wrapper"></div>
                  </div>

                  <div className="wp-block-columns">
                    <div className="wp-block-column wp-block-column-large">
                      <h3>DONATION OPTIONS​</h3>

                      <hr className="wp-block-separator" />

                      <div>
                        <img
                          alt="bank"
                          className="bank-logo"
                          src={"assets/images/bpi.png"}
                          width="115"
                          height="58"
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="bank-type">
                            <b>Online / Mobile Cash Transfer</b>
                          </div>
                          <ul className="mobile-info">
                            <li>
                              Log-in at BPI Express Mobile App, then click
                              the&nbsp;<b>Transfer</b>&nbsp;icon, then&nbsp;
                              <b>Transfer to Anyone</b>
                            </li>
                            <li>
                              Then enter the following details:
                              <ul>
                                <li>To Account: 9409-1016-04</li>
                                <li>(Account Name: Roland T. Votacion)</li>
                              </ul>
                            </li>
                            <li>
                              Click&nbsp;<b>Transfer</b>&nbsp;then&nbsp;
                              <b>Confirm</b>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="bank-type"></div>
                        </div>
                      </div>

                      <div>
                        <b>Bank Deposit</b>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <ul>
                            <li className="info">
                              BPI (Bank of the Philippine Islands)
                            </li>
                            <li className="info">
                              Account Name:&nbsp;Roland T. Votacion
                            </li>
                            <li className="info">
                              Account Number:&nbsp;9409 &#8211; 1016
                            </li>
                          </ul>
                        </div>
                      </div>

                      <hr className="wp-block-separator" />

                      <div>
                        <div>
                          <img
                            alt="bank"
                            className="bank-logo"
                            src={"assets/images/bdo.png"}
                            width="119"
                            height="60"
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="bank-type">
                              <strong>Online / Mobile Cash Transfer</strong>
                            </div>
                            <ul className="mobile-info">
                              <li>
                                Log-in at BDO Personal Mobile App, then click
                                the&nbsp;<b>Menu</b>&nbsp;icon, then&nbsp;
                                <b>Send Money</b>, then&nbsp;
                                <b>To any BDO Account</b>
                              </li>
                              <li>
                                Then enter the following details:
                                <ul>
                                  <li>Destination Account: 0038-0020-3205</li>
                                  <li>(Account Name: Roland T. Votacion)</li>
                                </ul>
                              </li>
                              <li>
                                Click&nbsp;<b>Transfer</b>&nbsp;then&nbsp;
                                <b>Confirm</b>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="bank-type">
                        <b>Bank Deposit</b>
                      </div>
                      <ul>
                        <li className="info">BDO (Banco de Oro)</li>
                        <li className="info">
                          Account Name:&nbsp;Roland T. Votacion
                        </li>
                        <li className="info">
                          Account Number:&nbsp;0038 &#8211; 0020 &#8211; 3205
                        </li>
                      </ul>

                      <hr className="wp-block-separator" />

                      <div>
                        <img
                          alt="bank"
                          className="bank-logo"
                          src={"assets/images/eastwest.png"}
                          width="143"
                          height="72"
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="bank-type">
                            <strong>Online / Mobile Cash Transfer</strong>
                          </div>
                          <ul className="mobile-info">
                            <li>
                              Log-in at EastWest Online Banking, then click
                              the&nbsp;<b>Accounts</b>&nbsp;menu,&nbsp;
                              <b>Make a Transfer</b>,&nbsp;<b>Send Money</b>
                              ,&nbsp;<b>then One Time Payment</b>
                            </li>
                            <li>
                              Then enter the following details:
                              <ul>
                                <li>Destination Account: 2000-3456-7661</li>
                                <li>
                                  (Account Name: Roland Torres Foundation, Inc.)
                                </li>
                              </ul>
                            </li>
                            <li>
                              Click&nbsp;<b>Transfer</b>&nbsp;then&nbsp;
                              <b>Confirm</b>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="bank-type">
                        <strong>Bank Deposit</strong>
                      </div>
                      <ul>
                        <li className="info">Eastwest Bank</li>
                        <li className="info">
                          Account Name:&nbsp;Roland Torres Foundation, Inc.
                        </li>
                        <li className="info">
                          Account Number:&nbsp;2000 &#8211; 3456 &#8211; 7661
                        </li>
                      </ul>

                      <hr className="wp-block-separator" />

                      <div>
                        <img
                          alt="bank"
                          className="bank-logo"
                          src={"assets/images/paypal.png"}
                          width="153"
                          height="77"
                        />
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="bank-type">
                            <strong>Online / Mobile Cash Transfer</strong>
                          </div>
                          <ul className="mobile-info">
                            <li>
                              Log-in at PayPal website, then click the&nbsp;
                              <b>Send Payment</b>&nbsp;at the menu
                            </li>
                            <li>
                              Then enter the following details:
                              <ul>
                                <li>
                                  To (e-mail):&nbsp;
                                  <b>rolandvotacion24@gmail.com</b>
                                </li>
                                <li>(Account Name: Roland T. Votacion)</li>
                                <li>
                                  What is the purpose of this payment?:&nbsp;
                                  <b>I&#8217;m making personal payments</b>
                                  &nbsp;(i.e., donations, shared expenses,
                                  rental charges, payment and others)
                                </li>
                                <li>(Account Name: Roland T. Votacion)</li>
                              </ul>
                            </li>
                            <li>
                              Click&nbsp;<b>Send Payment</b>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <hr className="wp-block-separator" />

                      <div>
                        <img
                          alt="bank"
                          className="bank-logo"
                          src={"assets/images/payoneer.png"}
                          width="151"
                          height="76"
                        />
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="bank-type">
                            <strong>Online / Mobile Cash Transfer</strong>
                          </div>
                          <ul className="mobile-info">
                            <li>
                              Log-in at Payoneer website, then click the&nbsp;
                              <b>Pay menu</b>&nbsp;then,&nbsp;
                              <b>Make a Payment</b>
                            </li>
                            <li>
                              Then enter the following details:
                              <ul>
                                <li>
                                  To (e-mail):&nbsp;
                                  <b>rolandvotacion30@gmail.com</b>
                                </li>
                                <li>(Account Name: Roland T. Votacion)</li>
                                <li>
                                  What is the purpose of this payment?:&nbsp;
                                  <b>I&#8217;m making personal payments</b>
                                  &nbsp;(i.e., Donations)
                                </li>
                              </ul>
                            </li>
                            <li>
                              Click&nbsp;<b>Send Payment</b>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="wp-block-column">
                      <form
                        name="checkout"
                        className="checkout woocommerce-checkout"
                      >
                        <h3 id="order_review_heading">Your order</h3>

                        <div
                          id="order_review"
                          className="woocommerce-checkout-review-order"
                        >
                          <table className="shop_table woocommerce-checkout-review-order-table">
                            <thead>
                              <tr>
                                <th className="product-name">Product</th>
                                <th className="product-total">Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cart.map((item, index) => {
                                return (
                                  <tr key={index} className="cart_item">
                                    <td className="product-name">
                                      {item.item.name}&nbsp;{" "}
                                      <strong className="product-quantity">
                                        &times;&nbsp;{item.orders}
                                      </strong>{" "}
                                    </td>
                                    <td className="product-total">
                                      <span className="woocommerce-Price-amount amount">
                                        <span className="woocommerce-Price-currencySymbol">
                                          &#8369;
                                        </span>
                                        {item.item.price * item.orders}
                                      </span>{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <tr className="cart-subtotal">
                                <th>Subtotal</th>
                                <td>
                                  <span className="woocommerce-Price-amount amount">
                                    <span className="woocommerce-Price-currencySymbol">
                                      &#8369;
                                    </span>
                                    {cartTotal}
                                  </span>
                                </td>
                              </tr>

                              <tr className="order-total">
                                <th>Total</th>
                                <td>
                                  <strong>
                                    <span className="woocommerce-Price-amount amount">
                                      <span className="woocommerce-Price-currencySymbol">
                                        &#8369;
                                      </span>
                                      {cartTotal}
                                    </span>
                                  </strong>{" "}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </form>

                      <p className="has-text-color has-text-align-center has-very-dark-gray-color">
                        <strong>
                          After depositing / sending your payment, please
                          confirm your payment to us by sending a scanned copy
                          or photo of the receipt).
                        </strong>
                      </p>

                      <div
                        role="form"
                        className="wpcf7"
                        id="wpcf7-f1301-p1125-o1"
                        lang="en-US"
                        dir="ltr"
                      >
                        <div
                          className="screen-reader-response"
                          role="alert"
                          aria-live="polite"
                        ></div>

                        <CheckoutForm isDonate={false}></CheckoutForm>
                      </div>
                    </div>
                  </div>

                  <p> </p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
