import { Link } from "react-router-dom";
import "./NewsEvents.css";

function NewsEvents() {
  return (
    <div>
      <div id="content" className="site-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-1092 page type-page status-publish ast-article-single"
                id="post-1092"
              >
                <header className="entry-header ast-header-without-markup"></header>
                <div className="entry-content clear">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="1092"
                    className="elementor elementor-1092"
                    data-elementor-settings="[]"
                  >
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <section
                          className="elementor-element elementor-element-0c62363 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="0c62363"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-background-overlay"></div>
                          <div className="elementor-container elementor-column-gap-wide">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-2928f02 elementor-column elementor-col-100 elementor-top-column"
                                data-id="2928f02"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-b5e3032 elementor-widget elementor-widget-spacer"
                                      data-id="b5e3032"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-9767a61 elementor-widget elementor-widget-heading"
                                      data-id="9767a61"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h3 className="elementor-heading-title elementor-size-default">
                                          News & Events
                                        </h3>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-3eb3983 elementor-widget elementor-widget-divider"
                                      data-id="3eb3983"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-a324d7c elementor-widget elementor-widget-heading"
                                      data-id="a324d7c"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-heading-title elementor-size-default">
                                          Be updated on the education of our
                                          scholars, Everything you need to know
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-4281555 elementor-widget elementor-widget-spacer"
                                      data-id="4281555"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-446ecb2 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="446ecb2"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-8a17ee9 elementor-column elementor-col-100 elementor-top-column"
                                data-id="8a17ee9"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-62fed6c elementor-widget elementor-widget-spacer"
                                      data-id="62fed6c"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-ccb58dc elementor-grid-eael-col-3 elementor-grid-tablet-eael-col-2 elementor-grid-mobile-eael-col-1 elementor-widget elementor-widget-eael-post-grid"
                                      data-id="ccb58dc"
                                      data-element_type="widget"
                                      data-settings='{"eael_post_grid_columns":"eael-col-3","eael_post_grid_columns_tablet":"eael-col-2","eael_post_grid_columns_mobile":"eael-col-1"}'
                                      data-widget_type="eael-post-grid.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          id="eael-post-grid-ccb58dc"
                                          className="eael-post-grid-container"
                                        >
                                          <div
                                            className="eael-post-grid eael-post-appender eael-post-appender-ccb58dc eael-post-grid-style-default"
                                            data-layout-mode="masonry"
                                          >
                                            <article
                                              className="eael-grid-post eael-post-grid-column"
                                              data-id="1779"
                                            >
                                              <div className="eael-grid-post-holder">
                                                <div className="eael-grid-post-holder-inner">
                                                  <div className="eael-entry-media">
                                                    <div className="eael-entry-overlay slide-up">
                                                      <i
                                                        className="fab fa-readme"
                                                        aria-hidden="true"
                                                      ></i>
                                                      <Link to="/education-in-this-new-normal-time-rtvfi-cares"></Link>
                                                    </div>
                                                    <div className="eael-entry-thumbnail">
                                                      <img
                                                        src="assets/images/RTV-Supplies.jpg"
                                                        alt=""
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="eael-entry-wrapper">
                                                    <header className="eael-entry-header">
                                                      <h2 className="eael-entry-title">
                                                        <Link
                                                          className="eael-grid-post-link"
                                                          to="/education-in-this-new-normal-time-rtvfi-cares"
                                                          title="Education in This &#8220;new normal&#8221; time: RTVfi cares"
                                                        >
                                                          Education in This
                                                          &#8220;new
                                                          normal&#8221; time:
                                                          RTVfi cares
                                                        </Link>
                                                      </h2>
                                                    </header>
                                                    <div className="eael-entry-content">
                                                      <div className="eael-grid-post-excerpt">
                                                        <p>
                                                          The onset of the
                                                          Covid-19 pandemic has
                                                          forced everyone to
                                                          adapt and adopt new
                                                          ways of living. The
                                                          lockdowns,
                                                          quarantines,...
                                                        </p>
                                                        <Link
                                                          to="/education-in-this-new-normal-time-rtvfi-cares"
                                                          className="eael-post-elements-readmore-btn"
                                                        >
                                                          Read More
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </article>
                                            <article
                                              className="eael-grid-post eael-post-grid-column"
                                              data-id="1715"
                                            >
                                              <div className="eael-grid-post-holder">
                                                <div className="eael-grid-post-holder-inner">
                                                  <div className="eael-entry-wrapper">
                                                    <header className="eael-entry-header">
                                                      <h2 className="eael-entry-title">
                                                        <Link
                                                          className="eael-grid-post-link"
                                                          to="/moving-ahead-with-purpose"
                                                          title="Moving  Ahead   with   Purpose"
                                                        >
                                                          Moving Ahead with
                                                          Purpose
                                                        </Link>
                                                      </h2>
                                                    </header>
                                                    <div className="eael-entry-content">
                                                      <div className="eael-grid-post-excerpt">
                                                        <p>
                                                          MOVING AHEAD WITH
                                                          PURPOSE: RTVFI 1st
                                                          General Assembly for
                                                          2020 The Roland Torres
                                                          Votacion Foundation
                                                          Inc. held its 1st
                                                          General...
                                                        </p>
                                                        <Link
                                                          to="/moving-ahead-with-purpose"
                                                          className="eael-post-elements-readmore-btn"
                                                        >
                                                          Read More
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </article>
                                            <article
                                              className="eael-grid-post eael-post-grid-column"
                                              data-id="1411"
                                            >
                                              <div className="eael-grid-post-holder">
                                                <div className="eael-grid-post-holder-inner">
                                                  <div className="eael-entry-wrapper">
                                                    <header className="eael-entry-header">
                                                      <h2 className="eael-entry-title">
                                                        <Link
                                                          className="eael-grid-post-link"
                                                          to="/school-bags-giving"
                                                          title="SCHOOL BAGS GIVING"
                                                        >
                                                          SCHOOL BAGS GIVING
                                                        </Link>
                                                      </h2>
                                                    </header>
                                                    <div className="eael-entry-content">
                                                      <div className="eael-grid-post-excerpt">
                                                        <p>
                                                          School bags and other
                                                          equipment to support
                                                          schooling have become
                                                          a very essential need
                                                          for children attending
                                                          school. School bags...
                                                        </p>
                                                        <Link
                                                          to="/school-bags-giving"
                                                          className="eael-post-elements-readmore-btn"
                                                        >
                                                          Read More
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </article>
                                            <article
                                              className="eael-grid-post eael-post-grid-column"
                                              data-id="1409"
                                            >
                                              <div className="eael-grid-post-holder">
                                                <div className="eael-grid-post-holder-inner">
                                                  <div className="eael-entry-wrapper">
                                                    <header className="eael-entry-header">
                                                      <h2 className="eael-entry-title">
                                                        <Link
                                                          className="eael-grid-post-link"
                                                          to="/school-supplies-giving"
                                                          title="School Supplies Giving"
                                                        >
                                                          School Supplies Giving
                                                        </Link>
                                                      </h2>
                                                    </header>
                                                    <div className="eael-entry-content">
                                                      <div className="eael-grid-post-excerpt">
                                                        <p>
                                                          More than 1 million
                                                          kids live in extreme
                                                          poverty in the
                                                          Philippines and arrive
                                                          on the first day of
                                                          school...
                                                        </p>
                                                        <Link
                                                          to="/school-supplies-giving"
                                                          className="eael-post-elements-readmore-btn"
                                                        >
                                                          Read More
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </article>
                                          </div>
                                          <div className="clearfix"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-7517cef elementor-widget elementor-widget-spacer"
                                      data-id="7517cef"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-87f6c88 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="87f6c88"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-6f2931e elementor-column elementor-col-100 elementor-top-column"
                                data-id="6f2931e"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-5e617b6 elementor-widget elementor-widget-spacer"
                                      data-id="5e617b6"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-658528a elementor-widget elementor-widget-divider"
                                      data-id="658528a"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-213aac3 elementor-widget elementor-widget-heading"
                                      data-id="213aac3"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <span className="elementor-heading-title elementor-size-large">
                                          You can help us make difference
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-d4a3992 elementor-widget elementor-widget-heading"
                                      data-id="d4a3992"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <span className="elementor-heading-title elementor-size-large">
                                          <span className="support-us">
                                            SUPPORT US AND CHANGE THE COURSE OF
                                            CHILD'S LIFE TODAY
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-e805da3 elementor-widget elementor-widget-button"
                                      data-id="e805da3"
                                      data-element_type="widget"
                                      data-widget_type="button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                          <Link
                                            to="/donate"
                                            className="elementor-button-link elementor-button elementor-size-lg"
                                            role="button"
                                          >
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-button-icon elementor-align-icon-right">
                                                <i
                                                  aria-hidden="true"
                                                  className="fab fa-paypal"
                                                ></i>
                                              </span>
                                              <span className="elementor-button-text">
                                                donate now
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-7d89508 elementor-widget elementor-widget-spacer"
                                      data-id="7d89508"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsEvents;
