import { Link } from "react-router-dom";
import "./SecondaryFooter.css";

function SecondaryFooter() {
  return (
    <div>
      <section
        className="elementor-element elementor-element-c16afb7 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
        data-id="c16afb7"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div
              className="elementor-element elementor-element-c372162 elementor-column elementor-col-100 elementor-top-column"
              data-id="c372162"
              data-element_type="column"
            >
              <div className="elementor-column-wrap  elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-0faf8e1 elementor-widget elementor-widget-spacer"
                    data-id="0faf8e1"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-spacer">
                        <div className="elementor-spacer-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-8560993 elementor-widget elementor-widget-divider"
                    data-id="8560993"
                    data-element_type="widget"
                    data-widget_type="divider.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-divider">
                        <span className="elementor-divider-separator"></span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-1dbf38a elementor-widget elementor-widget-heading"
                    data-id="1dbf38a"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <span className="elementor-heading-title elementor-size-large">
                        You can help us make difference
                      </span>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-a9fdf95 elementor-widget elementor-widget-heading"
                    data-id="a9fdf95"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <span className="elementor-heading-title elementor-size-large">
                        <span className="support-us">
                          SUPPORT US AND CHANGE THE COURSE OF CHILD'S LIFE TODAY
                        </span>
                        <span className="support-us"></span>
                      </span>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-485a925 elementor-widget elementor-widget-button"
                    data-id="485a925"
                    data-element_type="widget"
                    data-widget_type="button.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-button-wrapper">
                        <Link
                          to="/donate"
                          className="elementor-button-link elementor-button elementor-size-lg"
                          role="button"
                        >
                          <span className="elementor-button-content-wrapper">
                            <span className="elementor-button-icon elementor-align-icon-right">
                              <i
                                aria-hidden="true"
                                className="fab fa-paypal"
                              ></i>
                            </span>
                            <span className="elementor-button-text">
                              donate now
                            </span>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-3844b9f elementor-widget elementor-widget-spacer"
                    data-id="3844b9f"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-spacer">
                        <div className="elementor-spacer-inner"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SecondaryFooter;
