import { useState } from 'react';
import { ProductAPI } from '../../apis/productAPI';

import './CheckoutForm.css';
function CheckoutForm({ isDonate }) {
  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [contactNumber, setContactNumber] = useState(``);
  const [message, setMessage] = useState(``);
  const [donateTo, setDonateTo] = useState(`1`);
  const [receipt, setReceipt] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    const cartItems = JSON.parse(localStorage.getItem('cartItems'));
    const shapeItems = cartItems.map((_data) => {
      return {
        id: _data.id,
        quantity: _data.orders,
      };
    });
    const bodyFormData = new FormData();
    bodyFormData.append('name', name);
    bodyFormData.append('email', email);
    bodyFormData.append('contactNumber', contactNumber);
    bodyFormData.append('message', message);
    bodyFormData.append('receipt', receipt);
    bodyFormData.append('items', JSON.stringify(shapeItems));

    setIsDisabled(true);

    ProductAPI.checkout(bodyFormData).then((res) => {
      setIsDisabled(false);
      setIsSubmitted(true);
      localStorage.removeItem(`cartItems`);
      console.log('res :>> ', res);
    });
  };

  return (
    <div>
      <form
        onSubmit={(e) => submitForm(e)}
        className='wpcf7-form init'
        encType='multipart/form-data'
      >
        <p>
          <label>
            Your Name (required)
            <span className='wpcf7-form-control-wrap your-name'>
              <input
                type='text'
                name='your-name'
                size='40'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                aria-required='true'
                aria-invalid='false'
              />
            </span>
          </label>
          <br />
          <label>
            Your Email (required)
            <span className='wpcf7-form-control-wrap your-email'>
              <input
                type='email'
                name='your-email'
                size='40'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email'
                aria-required='true'
                aria-invalid='false'
              />
            </span>
          </label>
        </p>
        <p>
          <label>
            Contact # (Optional)
            <span className='wpcf7-form-control-wrap your-contact'>
              <input
                type='text'
                name='your-contact'
                size='40'
                className='wpcf7-form-control wpcf7-text'
                aria-invalid='false'
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </span>
          </label>
        </p>
        {isDonate ? (
          <p>
            <label>
              Donate To: (required)
              <span className='wpcf7-form-control-wrap menu-73'>
                <select
                  value={donateTo}
                  onChange={(e) => setDonateTo(e.target.value)}
                  name='menu-73'
                  className='wpcf7-form-control wpcf7-select'
                  aria-invalid='false'
                >
                  <option value='1'>Elementary / High School</option>
                  <option value='2'>College</option>
                </select>
              </span>
            </label>
          </p>
        ) : null}
        <p>
          <label>
            Your Message
            <br />
            <span className='wpcf7-form-control-wrap your-message'>
              <textarea
                name='your-message'
                cols='40'
                rows='10'
                className='wpcf7-form-control wpcf7-textarea'
                aria-invalid='false'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </span>
          </label>
        </p>
        <p>
          <label>
            Reciept (required)
            <br />
            <span className='wpcf7-form-control-wrap file-15'>
              <input
                type='file'
                name='file-15'
                size='40'
                required
                className='wpcf7-form-control wpcf7-file'
                accept='.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.ppt,.pptx,.odt,.avi,.ogg,.m4a,.mov,.mp3,.mp4,.mpg,.wav,.wmv'
                aria-invalid='false'
                onChange={(e) => setReceipt(e.target.files[0])}
              />
            </span>
          </label>
        </p>
        <p>
          <input
            type='submit'
            disabled={isDisabled || isSubmitted}
            value={
              isSubmitted
                ? 'Sent. Thank you!'
                : isDisabled
                ? 'Sending...'
                : 'Send'
            }
            className='wpcf7-form-control wpcf7-submit'
          />
        </p>
        <div
          className='wpcf7-response-output'
          role='alert'
          aria-hidden='true'
        ></div>
      </form>
    </div>
  );
}

export default CheckoutForm;
