import { Link } from "react-router-dom";
import "./SupportUs.css";

function SupportUs() {
  return (
    <div>
      <div id="content" className="site-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-1078 page type-page status-publish ast-article-single"
                id="post-1078"
              >
                <header className="entry-header ast-header-without-markup"></header>
                <div className="entry-content clear">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="1078"
                    className="elementor elementor-1078"
                    data-elementor-settings="[]"
                  >
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <section
                          className="elementor-element elementor-element-e711c87 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="e711c87"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-background-overlay"></div>
                          <div className="elementor-container elementor-column-gap-wide">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-72e31f4 elementor-column elementor-col-100 elementor-top-column"
                                data-id="72e31f4"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-3704794 elementor-widget elementor-widget-spacer"
                                      data-id="3704794"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-d337612 elementor-widget elementor-widget-heading"
                                      data-id="d337612"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h3 className="elementor-heading-title elementor-size-default">
                                          Support Us​
                                        </h3>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-d658566 elementor-widget elementor-widget-divider"
                                      data-id="d658566"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-113b542 elementor-widget elementor-widget-heading"
                                      data-id="113b542"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-heading-title elementor-size-default">
                                          Your contribution and donation to RTV
                                          Foundation allows us to continue to
                                          support the education of our scholars.
                                          Make a difference today!​
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-7c95718 elementor-widget elementor-widget-spacer"
                                      data-id="7c95718"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-f453be9 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="f453be9"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-ac69bb5 elementor-column elementor-col-100 elementor-top-column"
                                data-id="ac69bb5"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-9b74238 elementor-widget elementor-widget-spacer"
                                      data-id="9b74238"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-9746134 elementor-widget elementor-widget-heading"
                                      data-id="9746134"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          FUNDRAISING​
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-38a6d06 elementor-widget elementor-widget-divider"
                                      data-id="38a6d06"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-element elementor-element-f0d0bd4 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="f0d0bd4"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-0239e93 elementor-column elementor-col-50 elementor-inner-column"
                                            data-id="0239e93"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-1264645 elementor-widget elementor-widget-text-editor"
                                                  data-id="1264645"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        Through private
                                                        donations, the Roland
                                                        Torres Votacion
                                                        Foundation, Inc. has
                                                        been able to give awards
                                                        and grants, a yearly
                                                        event and program that
                                                        benefits the student and
                                                        scholars of RTFVI. The
                                                        Foundation is hosting
                                                        several fundraising
                                                        events throughout the
                                                        year that allow everyone
                                                        to join in support of
                                                        its scholars.
                                                      </p>
                                                      <p>
                                                        These special events
                                                        generate thousands of
                                                        supports while offering
                                                        bigger opportunities for
                                                        RTVFI scholars to
                                                        fulfill their dreams.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-a1dd312 elementor-mobile-align-center elementor-widget elementor-widget-button"
                                                  data-id="a1dd312"
                                                  data-element_type="widget"
                                                  data-widget_type="button.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-button-wrapper">
                                                      <Link
                                                        to="/donate"
                                                        className="elementor-button-link elementor-button elementor-size-md"
                                                        role="button"
                                                      >
                                                        <span className="elementor-button-content-wrapper">
                                                          <span className="elementor-button-text">
                                                            Donate Now!
                                                          </span>
                                                        </span>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-19b0e68 elementor-column elementor-col-50 elementor-inner-column"
                                            data-id="19b0e68"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-22dfc08 elementor-widget elementor-widget-image"
                                                  data-id="22dfc08"
                                                  data-element_type="widget"
                                                  data-widget_type="image.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-image">
                                                      <img
                                                        width="1024"
                                                        height="768"
                                                        src={
                                                          "assets/images/IMG_20190525_111933-1024x768-1.jpg"
                                                        }
                                                        className="attachment-large size-large"
                                                        alt=""
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <div
                                      className="elementor-element elementor-element-c61e3dc elementor-widget elementor-widget-spacer"
                                      data-id="c61e3dc"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-40c6143 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="40c6143"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-0679782 elementor-column elementor-col-50 elementor-top-column"
                                data-id="0679782"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-24d311a elementor-widget elementor-widget-spacer"
                                      data-id="24d311a"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-2d66349 elementor-widget elementor-widget-image"
                                      data-id="2d66349"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-image">
                                          <img
                                            width="1024"
                                            height="767"
                                            src="assets/images/20191130_095827-1024x767-1.jpg"
                                            className="attachment-large size-large"
                                            alt=""
                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-aaf640e elementor-widget elementor-widget-spacer"
                                      data-id="aaf640e"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-60a5284 elementor-column elementor-col-50 elementor-top-column"
                                data-id="60a5284"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-b4fa034 elementor-widget elementor-widget-spacer"
                                      data-id="b4fa034"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-e878f75 elementor-widget elementor-widget-heading"
                                      data-id="e878f75"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          VOLUNTEER
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-8fbc28d elementor-widget elementor-widget-divider"
                                      data-id="8fbc28d"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-19960a2 elementor-widget elementor-widget-text-editor"
                                      data-id="19960a2"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <p>
                                            You too can make a difference by
                                            volunteering to our community
                                            services. You will have the
                                            opportunity to learn new things with
                                            our staff and other volunteers. The
                                            possibilities are endless, and we’re
                                            excited for you to get started!
                                            <br />
                                            Email us at
                                            rtvfoundation0925@gmail.com to learn
                                            more about how YOU can help.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-8e742c2 elementor-mobile-align-center elementor-widget elementor-widget-button"
                                      data-id="8e742c2"
                                      data-element_type="widget"
                                      data-widget_type="button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                          <Link
                                            to="/contact-us"
                                            className="elementor-button-link elementor-button elementor-size-md"
                                            role="button"
                                          >
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-button-text">
                                                volunteer now!
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-9659343 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="9659343"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-18b09a6 elementor-column elementor-col-50 elementor-top-column"
                                data-id="18b09a6"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-ca370bc elementor-widget elementor-widget-spacer"
                                      data-id="ca370bc"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-69a3e97 elementor-widget elementor-widget-heading"
                                      data-id="69a3e97"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          PARTNER WITH US
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-ee05669 elementor-widget elementor-widget-divider"
                                      data-id="ee05669"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-7614a5e elementor-widget elementor-widget-text-editor"
                                      data-id="7614a5e"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <p>
                                            Be our partner! The Foundation is
                                            not able to give in the way that it
                                            does without the generosity of our
                                            partners. We enjoin you in helping
                                            us to enrich the lives of our
                                            scholars.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-dc35fc8 elementor-mobile-align-center elementor-widget elementor-widget-button"
                                      data-id="dc35fc8"
                                      data-element_type="widget"
                                      data-widget_type="button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                          <Link
                                            to="/contact-us"
                                            className="elementor-button-link elementor-button elementor-size-md"
                                            role="button"
                                          >
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-button-text">
                                                become a partner
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-1a4d107 elementor-column elementor-col-50 elementor-top-column"
                                data-id="1a4d107"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-0484851 elementor-widget elementor-widget-spacer"
                                      data-id="0484851"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-1daf8e0 elementor-widget elementor-widget-image"
                                      data-id="1daf8e0"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-image">
                                          <img
                                            width="1024"
                                            height="579"
                                            src="assets/images/DSC_0022-1024x579.jpg"
                                            className="attachment-large size-large"
                                            alt=""
                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-3372e45 elementor-widget elementor-widget-spacer"
                                      data-id="3372e45"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-fe0fb8c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="fe0fb8c"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-8ab99eb elementor-column elementor-col-50 elementor-top-column"
                                data-id="8ab99eb"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-928cea8 elementor-widget elementor-widget-spacer"
                                      data-id="928cea8"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-3803c64 elementor-widget elementor-widget-image"
                                      data-id="3803c64"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-image">
                                          <img
                                            width="768"
                                            height="1024"
                                            src="assets/images/IMG_20190525_121018-768x1024.jpg"
                                            className="attachment-large size-large"
                                            alt=""
                                            sizes="(max-width: 768px) 100vw, 768px"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-24d84f1 elementor-widget elementor-widget-spacer"
                                      data-id="24d84f1"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-157fcfe elementor-column elementor-col-50 elementor-top-column"
                                data-id="157fcfe"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-e3428a1 elementor-widget elementor-widget-spacer"
                                      data-id="e3428a1"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-dfdcb05 elementor-widget elementor-widget-heading"
                                      data-id="dfdcb05"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          WAYS TO DONATE
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-4b2f7ae elementor-widget elementor-widget-divider"
                                      data-id="4b2f7ae"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-8ade4a3 elementor-widget elementor-widget-text-editor"
                                      data-id="8ade4a3"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <p>
                                            We give you many options to donate,
                                            whether in the form of cash, school
                                            bags, school supplies, shoes or any
                                            useful stuff.
                                            <br />
                                            Your generosity impacts the lives of
                                            our scholars, their families,
                                            teachers, and the community as a
                                            whole.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-5d984f3 elementor-mobile-align-center elementor-widget elementor-widget-button"
                                      data-id="5d984f3"
                                      data-element_type="widget"
                                      data-widget_type="button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                          <Link
                                            to="/donate"
                                            className="elementor-button-link elementor-button elementor-size-md"
                                            role="button"
                                          >
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-button-text">
                                                donate now!
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-1f05fcb elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="1f05fcb"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-ef183ee elementor-column elementor-col-100 elementor-top-column"
                                data-id="ef183ee"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-05d0bbe elementor-widget elementor-widget-spacer"
                                      data-id="05d0bbe"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-ed9cbc0 elementor-widget elementor-widget-heading"
                                      data-id="ed9cbc0"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          WAYS YOU CAN GIVE​
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-2c8a178 elementor-widget elementor-widget-divider"
                                      data-id="2c8a178"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-element elementor-element-30348b8 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="30348b8"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-0e301fc elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="0e301fc"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-dab8df2 elementor-widget elementor-widget-text-editor"
                                                  data-id="dab8df2"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p className="icon-image-holder">
                                                        <img
                                                          alt=""
                                                          className="icon-img padding-xs"
                                                          src={
                                                            "assets/images/8.png"
                                                          }
                                                        ></img>
                                                      </p>

                                                      <h4 className="text-center fullfill-text">
                                                        <span className="fullfill-wish">
                                                          Fulfill a wish
                                                        </span>
                                                      </h4>
                                                      <div className="text-md light icon-image-holder">
                                                        Please check our
                                                        WISHLIST to see our
                                                        current needs.
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-52f6d24 elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="52f6d24"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-274b155 elementor-widget elementor-widget-text-editor"
                                                  data-id="274b155"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p className="icon-image-holder">
                                                        <img
                                                          alt=""
                                                          className="icon-img padding-xs"
                                                          src={
                                                            "assets/images/6.png"
                                                          }
                                                        />
                                                      </p>

                                                      <h4
                                                        className="text-center fullfill-text"
                                                        data-target="#myModal"
                                                        data-toggle="modal"
                                                      >
                                                        <span
                                                          className="fullfill-wish"
                                                          data-target="#desktop"
                                                          data-toggle="modal"
                                                        >
                                                          Pay a tuition
                                                        </span>
                                                      </h4>
                                                      <div className="text-md light icon-image-holder">
                                                        The easiest yet the most
                                                        generous way to support
                                                        our scholars.
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-9a62c4d elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="9a62c4d"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-61b2ae0 elementor-widget elementor-widget-text-editor"
                                                  data-id="61b2ae0"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p className="icon-image-holder">
                                                        <img
                                                          alt=""
                                                          src={
                                                            "assets/images/10.png"
                                                          }
                                                        />
                                                      </p>

                                                      <h4
                                                        className="fullfill-text"
                                                        data-target="#myModal"
                                                        data-toggle="modal"
                                                      >
                                                        <span
                                                          className="fullfill-wish"
                                                          data-target="#desktop"
                                                          data-toggle="modal"
                                                        >
                                                          Buy bag and supplies
                                                        </span>
                                                      </h4>
                                                      <div className="icon-image-holder">
                                                        Bags and school supplies
                                                        are basic needs our
                                                        scholars can receive
                                                        from you
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <div
                                      className="elementor-element elementor-element-940ca8f elementor-widget elementor-widget-spacer"
                                      data-id="940ca8f"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-9e2fbe5 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="9e2fbe5"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-f6c4bdd elementor-column elementor-col-100 elementor-top-column"
                                data-id="f6c4bdd"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-b3fb47b elementor-widget elementor-widget-spacer"
                                      data-id="b3fb47b"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-f8b2737 elementor-widget elementor-widget-divider"
                                      data-id="f8b2737"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-cf99d10 elementor-widget elementor-widget-heading"
                                      data-id="cf99d10"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <span className="elementor-heading-title elementor-size-large">
                                          You can help us make difference
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-a6125d2 elementor-widget elementor-widget-heading"
                                      data-id="a6125d2"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <span className="elementor-heading-title elementor-size-large">
                                          <span className="support-us">
                                            SUPPORT US AND CHANGE THE COURSE OF
                                            CHILD'S LIFE TODAY
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-7d58a62 elementor-widget elementor-widget-button"
                                      data-id="7d58a62"
                                      data-element_type="widget"
                                      data-widget_type="button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                          <Link
                                            to="/donate"
                                            className="elementor-button-link elementor-button elementor-size-lg"
                                            role="button"
                                          >
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-button-icon elementor-align-icon-right">
                                                <i
                                                  aria-hidden="true"
                                                  className="fab fa-paypal"
                                                ></i>
                                              </span>
                                              <span className="elementor-button-text">
                                                donate now
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-d44b941 elementor-widget elementor-widget-spacer"
                                      data-id="d44b941"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportUs;
