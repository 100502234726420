import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProductAPI } from "../../apis/productAPI";
import "./GiveAGift.css";

function GiveAGift() {
  const [gifts, setGifts] = useState([]);
  const cartItems = JSON.parse(localStorage.getItem(`cartItems`) || `[]`);
  const [cart, setCart] = useState(cartItems);

  useEffect(() => {
    const mapItems = (products) => {
      return products.map((item) => {
        item.isLoading = false;
        item.isAdded = false;
        return item;
      });
    };
    ProductAPI.getAll().then((res) => {
      const theProducts = mapItems(res);
      console.log("theProducts :>> ", theProducts);
      setGifts(theProducts);
    });
  }, []);

  const setGiftButtonState = (index) => {
    gifts[index].isAdded = true;
    setGifts(gifts);
  };

  const addToCart = (e, item, index) => {
    e.preventDefault();
    const thecartItems = JSON.parse(JSON.stringify(cart));
    const INITIAL_ORDER_VALUE = 1;
    console.log("item :>> ", cart, item, index);

    //check if is array
    const isInCart = thecartItems.filter((order) => {
      return order.id === item.id;
    });

    if (isInCart.length) {
      const newItemValues = thecartItems.map((order, index) => {
        if (order.id === item.id) {
          order.orders = order.orders + INITIAL_ORDER_VALUE;
        }
        return order;
      });

      setCart(newItemValues);
      setGiftButtonState(index);
      console.log("newItemValues :>> ", newItemValues);
      localStorage.setItem(`cartItems`, JSON.stringify(newItemValues));
    } else {
      thecartItems.push({
        orders: INITIAL_ORDER_VALUE,
        id: item.id,
        item,
      });
      console.log("thecartItems :>> ", thecartItems);
      setCart(thecartItems);
      setGiftButtonState(index);
      localStorage.setItem(`cartItems`, JSON.stringify(thecartItems));
    }
  };

  return (
    <>
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <div
          id="liveToast"
          className={`toast text-white bg-primary`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-body">
            Hello, world! This is a toast message.
          </div>
        </div>
      </div>

      <div>
        <div id="content" className="site-content">
          <div className="ast-container">
            <div id="primary" className="content-area primary">
              <main id="main" className="site-main">
                <article
                  className="post-1088 page type-page status-publish ast-article-single"
                  id="post-1088"
                >
                  <header className="entry-header ast-header-without-markup"></header>
                  <div className="entry-content clear">
                    <div
                      data-elementor-type="wp-page"
                      data-elementor-id="1088"
                      className="elementor elementor-1088"
                      data-elementor-settings="[]"
                    >
                      <div className="elementor-inner">
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-element elementor-element-7e31ea7 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="7e31ea7"
                            data-element_type="section"
                            data-settings='{"background_background":"classic"}'
                          >
                            <div className="elementor-background-overlay"></div>
                            <div className="elementor-container elementor-column-gap-wide">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-a56e23e elementor-column elementor-col-100 elementor-top-column"
                                  data-id="a56e23e"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-18aafb8 elementor-widget elementor-widget-spacer"
                                        data-id="18aafb8"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-8636227 elementor-widget elementor-widget-heading"
                                        data-id="8636227"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <h3 className="elementor-heading-title elementor-size-default">
                                            Give a Gift
                                          </h3>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-bef0ce4 elementor-widget elementor-widget-divider"
                                        data-id="bef0ce4"
                                        data-element_type="widget"
                                        data-widget_type="divider.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-divider">
                                            <span className="elementor-divider-separator"></span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-89c40b6 elementor-widget elementor-widget-heading"
                                        data-id="89c40b6"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-heading-title elementor-size-default">
                                            Shop and give a gift of education to
                                            young children for their future.
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-05b521f elementor-widget elementor-widget-spacer"
                                        data-id="05b521f"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-15e3992 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="15e3992"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-fa3a079 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="fa3a079"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-2584a79 elementor-widget elementor-widget-spacer"
                                        data-id="2584a79"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-9e05a68 elementor-widget elementor-widget-heading"
                                        data-id="9e05a68"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <h2 className="elementor-heading-title elementor-size-default">
                                            Choose a life-changing stuff and
                                            give it as a gift to one of our
                                            scholars who need it the most
                                          </h2>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-626b33b elementor-widget elementor-widget-divider"
                                        data-id="626b33b"
                                        data-element_type="widget"
                                        data-widget_type="divider.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-divider">
                                            <span className="elementor-divider-separator"></span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-ad658ca elementor-widget elementor-widget-text-editor"
                                        data-id="ad658ca"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <div
                                              className="div2"
                                              align="center"
                                            >
                                              <div className="content-container">
                                                <p className="text-md light content">
                                                  The RTVFI gift giving program
                                                  directly delivers the gifts to
                                                  all our scholars. You can help
                                                  many children within your
                                                  fingertips wherever you are in
                                                  the globe. At the moment, the
                                                  foundation has 265 scholars
                                                  and we are targeting 1,000
                                                  scholars in 2025.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="row div3">
                                              <div className="col-md-4"> </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-fae7046 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="fae7046"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-6e19ed9 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="6e19ed9"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-25648f6 elementor-widget elementor-widget-shortcode"
                                        data-id="25648f6"
                                        data-element_type="widget"
                                        data-widget_type="shortcode.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-shortcode">
                                            <div className="woocommerce columns-4 ">
                                              <ul className="products columns-4">
                                                {gifts.length ? (
                                                  gifts.map((item, index) => {
                                                    return (
                                                      <li
                                                        key={index}
                                                        className="ast-article-single product type-product post-1134 status-publish first instock product_cat-school-supplies has-post-thumbnail shipping-taxable purchasable product-type-simple"
                                                      >
                                                        <div className="astra-shop-thumbnail-wrap mb-2">
                                                          <div className="woocommerce-LoopProduct-link woocommerce-loop-product__link">
                                                            <img
                                                              width="300"
                                                              height="300"
                                                              src={
                                                                `assets/images${item.imageUrl}`
                                                              }
                                                              className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                                              alt=""
                                                              sizes="(max-width: 300px) 100vw, 300px"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="astra-shop-summary-wrap">
                                                          <span className="ast-woo-product-category">
                                                            {item.category}
                                                          </span>
                                                          <div className="ast-loop-product__link">
                                                            <h2 className="woocommerce-loop-product__title">
                                                              {item.name}
                                                            </h2>
                                                          </div>
                                                          <span className="price">
                                                            <span className="woocommerce-Price-amount amount">
                                                              <span className="woocommerce-Price-currencySymbol">
                                                                &#8369;
                                                              </span>
                                                              {item.price}
                                                            </span>
                                                          </span>
                                                          <a
                                                            href="add"
                                                            onClick={(e) =>
                                                              addToCart(
                                                                e,
                                                                item,
                                                                index
                                                              )
                                                            }
                                                            data-quantity="1"
                                                            className={`button product_type_simple add_to_cart_button ajax_add_to_cart ${
                                                              item.isLoading
                                                                ? `loading`
                                                                : ``
                                                            } ${
                                                              item.isAdded
                                                                ? `added`
                                                                : ``
                                                            }`}
                                                            data-product_id="1134"
                                                            data-product_sku=""
                                                            aria-label="Add &ldquo;100 Ball Pens&rdquo; to your cart"
                                                            rel="nofollow"
                                                          >
                                                            Donate Item
                                                          </a>
                                                          {item?.isAdded &&
                                                          !item?.isLoading ? (
                                                            <Link
                                                              to="/cart"
                                                              className="added_to_cart wc-forward"
                                                              title="View cart"
                                                            >
                                                              View cart
                                                            </Link>
                                                          ) : null}
                                                        </div>
                                                      </li>
                                                    );
                                                  })
                                                ) : (
                                                  <li>No item</li>
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-168d6b7 elementor-widget elementor-widget-spacer"
                                        data-id="168d6b7"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-6a4777d elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="6a4777d"
                            data-element_type="section"
                            data-settings='{"background_background":"classic"}'
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-756003e elementor-column elementor-col-100 elementor-top-column"
                                  data-id="756003e"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-e921abe elementor-widget elementor-widget-spacer"
                                        data-id="e921abe"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-3db52f4 elementor-widget elementor-widget-divider"
                                        data-id="3db52f4"
                                        data-element_type="widget"
                                        data-widget_type="divider.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-divider">
                                            <span className="elementor-divider-separator"></span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-891f68b elementor-widget elementor-widget-heading"
                                        data-id="891f68b"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <span className="elementor-heading-title elementor-size-large">
                                            You can help us make difference
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-8e1d1c7 elementor-widget elementor-widget-heading"
                                        data-id="8e1d1c7"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <span className="elementor-heading-title elementor-size-large">
                                            <span className="support-us">
                                              SUPPORT US AND CHANGE THE COURSE
                                              OF CHILD'S LIFE TODAY
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-3b11c87 elementor-widget elementor-widget-button"
                                        data-id="3b11c87"
                                        data-element_type="widget"
                                        data-widget_type="button.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-button-wrapper">
                                            <Link
                                              to="/donate"
                                              className="elementor-button-link elementor-button elementor-size-lg"
                                              role="button"
                                            >
                                              <span className="elementor-button-content-wrapper">
                                                <span className="elementor-button-icon elementor-align-icon-right">
                                                  <i
                                                    aria-hidden="true"
                                                    className="fab fa-paypal"
                                                  ></i>
                                                </span>
                                                <span className="elementor-button-text">
                                                  donate now
                                                </span>
                                              </span>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-be3fe81 elementor-widget elementor-widget-spacer"
                                        data-id="be3fe81"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GiveAGift;
