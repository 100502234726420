import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Cart.css";

function Cart() {
  const cartItems = JSON.parse(localStorage.getItem(`cartItems`) || `[]`);
  const [cart, setCart] = useState(cartItems);
  const [cartTotal, setCartTotal] = useState(0);

  useEffect(() => {
    let theTotal = 0;
    cart.map((item, index) => {
      theTotal = theTotal + (item.orders * item.item.price);
      return item;
    });
    setCartTotal(theTotal)
  }, [cart]);

  const removeItem = (e, index) => {
    e.preventDefault();
    const newList = cart.filter((item, idx) => {
      return index !== idx;
    });
    setCart(newList);
    localStorage.setItem(`cartItems`, JSON.stringify(newList));
  };

  const changeOrders = (e, index) => {
    console.log('e.target.value :>> ', index, e.target.value);
    const newCart = cart.map((item, i ) => {
      if (i === index) {
        item.orders = e.target.value;
      }
      return item;
    });
    setCart(newCart);
    localStorage.setItem(`cartItems`, JSON.stringify(newCart));
  }

  return (
    <div id="add_payment_method">
      <div id="content" className="site-content site-card-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-1124 page type-page status-publish ast-article-single"
                id="post-1124"
              >
                <header className="entry-header ast-header-without-markup"></header>
                <div className="entry-content clear">
                  <h2 className="has-text-align-center">
                    You are about to donate life-changing gifts to our scholars.
                    <br />
                    You can dedicate these gifts to our scholars who have been
                    fighting for their dreams.
                  </h2>

                  <div className="woocommerce">
                    <div className="woocommerce-notices-wrapper"></div>
                    {cart.length ? (
                      <div>
                        <form
                          className="woocommerce-cart-form"
                        >
                          <table
                            className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                            cellSpacing="0"
                          >
                            <thead>
                              <tr>
                                <th className="product-remove">&nbsp;</th>
                                <th className="product-thumbnail">&nbsp;</th>
                                <th className="product-name">Product</th>
                                <th className="product-price">Price</th>
                                <th className="product-quantity">Quantity</th>
                                <th className="product-subtotal">Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                cart.map((item, index) => {
                                  return (
                                    <tr key={index} className="woocommerce-cart-form__cart-item cart_item">
                                      <td className="product-remove">
                                        <a
                                          href="order"
                                          onClick={(e) => removeItem(e, index)}
                                          className="remove"
                                          aria-label="Remove this item"
                                          data-product_id="1134"
                                          data-product_sku=""
                                        >
                                          ×
                                        </a>{" "}
                                      </td>

                                      <td className="product-thumbnail">
                                        <span>
                                          <img
                                            width="300"
                                            height="300"
                                            src={`assets/images${item.item.imageUrl}`}
                                            className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail hoverZoomLink"
                                            alt=""
                                            sizes="(max-width: 300px) 100vw, 300px"
                                          />
                                        </span>{" "}
                                      </td>

                                      <td
                                        className="product-name"
                                        data-title="Product"
                                      >
                                        <span className="text-primary">
                                          {item.item.name}
                                        </span>{" "}
                                      </td>

                                      <td
                                        className="product-price"
                                        data-title="Price"
                                      >
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">
                                            ₱
                                          </span>
                                          {item.item.price}
                                        </span>{" "}
                                      </td>

                                      <td
                                        className="product-quantity"
                                        data-title="Quantity"
                                      >
                                        <div className="quantity">
                                          <label
                                            className="screen-reader-text"
                                            htmlFor="quantity_6316edd1b97be"
                                          >
                                            100 Ball Pens quantity
                                          </label>
                                          <input
                                            type="number"
                                            id="quantity_6316edd1b97be"
                                            className="input-text qty text"
                                            step="1"
                                            min="0"
                                            max=""
                                            name="cart[7c9d0b1f96aebd7b5eca8c3edaa19ebb][qty]"
                                            title="Qty"
                                            size="4" value={item.orders} onChange={(e) => changeOrders(e, index)}
                                            inputMode="numeric"
                                          />
                                        </div>
                                      </td>

                                      <td
                                        className="product-subtotal"
                                        data-title="Subtotal"
                                      >
                                        <span className="woocommerce-Price-amount amount">
                                          <span className="woocommerce-Price-currencySymbol">
                                            ₱
                                          </span>
                                          {item.item.price * item.orders}
                                        </span>{" "}
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </form>
                        <div className="cart-collaterals">
                          <div className="cart_totals">
                            <h2>Cart totals</h2>

                            <table
                              cellSpacing="0"
                              className="shop_table shop_table_responsive"
                            >
                              <tbody>
                                <tr className="cart-subtotal">
                                  <th>Subtotal</th>
                                  <td data-title="Subtotal">
                                    <span className="woocommerce-Price-amount amount">
                                      <span className="woocommerce-Price-currencySymbol">
                                        ₱
                                      </span>
                                      {cartTotal}
                                    </span>
                                  </td>
                                </tr>

                                <tr className="order-total">
                                  <th>Total</th>
                                  <td data-title="Total">
                                    <strong>
                                      <span className="woocommerce-Price-amount amount">
                                        <span className="woocommerce-Price-currencySymbol">
                                          ₱
                                        </span>
                                        {cartTotal}
                                      </span>
                                    </strong>{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div className="wc-proceed-to-checkout">
                              <Link
                                to="/checkout" style={{pointerEvents: !cartTotal ? 'none' : '', cursor: 'disabled'}}
                                className="checkout-button button alt wc-forward"
                              >
                                {!cartTotal ? (
                                  <>
                                  <i className="fa fa-exclamation-circle text-danger fa-fw"></i>
                                  Add something to proceed
                                  </>
                                ): 'Proceed to checkout'}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className="cart-empty woocommerce-info">
                          <i
                            className="fa-fw fa fa-info-circle text-primary"
                            aria-hidden="true"
                          ></i>
                          Your cart is currently empty.
                        </p>
                        <p className="return-to-shop">
                          <Link
                            className="button wc-backward"
                            to="/give-a-gift"
                          >
                            Return to shop{" "}
                          </Link>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
