import "./EducationNewNormal.css";
import { NavLink } from "react-router-dom";

function EducationNewNormal() {
  return (
    <div>
      <div id="content" className="site-content site-card-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-1779 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized ast-article-single"
                id="post-1779"
              >
                <div className="ast-post-format- single-layout-1">
                  <header className="entry-header ">
                    <div className="post-thumb-img-content post-thumb">
                      <img
                        width="960"
                        height="720"
                        src="assets/images/RTV-Supplies.jpg"
                        className="attachment-large size-large wp-post-image"
                        alt=""
                      />
                    </div>
                    <div className="ast-single-post-order">
                      <h1 className="entry-title" itemProp="headline">
                        Education in This &#8220;new normal&#8221; time: RTVfi
                        cares
                      </h1>
                      <div className="entry-meta">
                        <span className="posted-on">
                          <span className="published" itemProp="datePublished">
                            {" "}
                            August 24, 2020
                          </span>
                          <span className="updated" itemProp="dateModified">
                            {" "}
                            August 26, 2020
                          </span>
                        </span>{" "}
                        / By admin
                      </div>
                    </div>
                  </header>

                  <div className="entry-content clear" itemProp="text">
                    <div
                      data-elementor-type="wp-post"
                      data-elementor-id="1779"
                      className="elementor elementor-1779"
                      data-elementor-settings="[]"
                    >
                      <div className="elementor-inner">
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-element elementor-element-2d0b385 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="2d0b385"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-36e940bf elementor-column elementor-col-100 elementor-top-column"
                                  data-id="36e940bf"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-6a260e28 elementor-widget elementor-widget-text-editor"
                                        data-id="6a260e28"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              The onset of the Covid-19 pandemic
                                              has forced everyone to adapt and
                                              adopt new ways of living. The
                                              lockdowns, quarantines, masks,
                                              physical distancing, and
                                              technology are now a
                                              &#8220;must&#8221; to continue
                                              living. Limiting our movements,
                                              travels, transaction, and even
                                              education; dramatically changes
                                              our lives.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-ed8a5fd elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="ed8a5fd"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-33c8128 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="33c8128"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-e664427 elementor-widget elementor-widget-heading"
                                        data-id="e664427"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <h2 className="elementor-heading-title elementor-size-default">
                                            <span className="new-normal">
                                              New Normal Education
                                            </span>
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-9e8a1fb elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="9e8a1fb"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-d5544d2 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="d5544d2"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-4b9f130 elementor-widget elementor-widget-text-editor"
                                        data-id="4b9f130"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="what-is">
                                                What is &#8220;New
                                                Normal&#8221;? The American
                                                Dictionary defines a new normal
                                                as &#8220;a previously
                                                unfamiliar or atypical situation
                                                that become standard, usual or
                                                expected. &#8221; Today, the
                                                omniscient support of technology
                                                considers being vital in the new
                                                normal education.
                                              </span>
                                              <br></br>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-01fb99a elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="01fb99a"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-338635d elementor-column elementor-col-100 elementor-top-column"
                                  data-id="338635d"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-99d3705 elementor-widget elementor-widget-text-editor"
                                        data-id="99d3705"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="new-norm">
                                                New normal education voids the
                                                preceding of face-to-face
                                                classes, utilizing online and
                                                blended methodologies. With so
                                                much to consider, many students,
                                                parents, and educators struggle
                                                to keep up with the sudden shift
                                              </span>
                                              <span>
                                                <span className="dashe">—</span>
                                              </span>
                                              <span className="new-norm">
                                                resource-wise and culture
                                                itself. It is hard to get
                                                everything ready, such as
                                                gadgets, devices, and the
                                                internet, typically available
                                                only in urban areas. The
                                                students in the outskirt of the
                                                mountains may find these
                                                requirements a dead-end.
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-cf3e6aa elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="cf3e6aa"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-0904418 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="0904418"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-2911b9f elementor-widget elementor-widget-text-editor"
                                        data-id="2911b9f"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="knowledge">
                                                The knowledge of how different
                                                education in the mountaintop and
                                                the city is never new. While
                                                urbanized areas have the luxury
                                                of transport, availability of
                                                the technology, the abundance of
                                                school supplies and materials,
                                                rural areas usually get the
                                                shorter end of the stick. In
                                                rural areas Some students need
                                                to walk for hours just to get to
                                                school. The use of devices such
                                                as computers and mobile phones
                                                is limited, and internet
                                                connection is close to
                                                non-existent. For the longest
                                                time, educators are struggling
                                                to retain students and provide
                                                materials for their class. Now
                                                imagine how the new normal
                                                teaching will affect education
                                                in rural areas.
                                              </span>
                                              <br></br>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-f096e1b elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="f096e1b"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-29d4e71 elementor-column elementor-col-50 elementor-top-column"
                                  data-id="29d4e71"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-7a68364 elementor-widget elementor-widget-image"
                                        data-id="7a68364"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-image">
                                            <img
                                              width="1024"
                                              height="768"
                                              src="assets/images/20200814_150351-1-scaled.jpg"
                                              className="attachment-large size-large"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-069805b elementor-column elementor-col-50 elementor-top-column"
                                  data-id="069805b"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-be12e20 elementor-widget elementor-widget-image"
                                        data-id="be12e20"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-image">
                                            <img
                                              width="360"
                                              height="277"
                                              src="assets/images/rsz_rtv_supplies7.jpg"
                                              className="attachment-large size-large"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-8158eeb elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="8158eeb"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-49508a8 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="49508a8"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-ee370fb elementor-widget elementor-widget-text-editor"
                                        data-id="ee370fb"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="school-supp">
                                                School Supplies for 365 Scholars
                                              </span>
                                              <br></br>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-8cd6ec8 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="8cd6ec8"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-4de2846 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="4de2846"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-2c582a1 elementor-widget elementor-widget-text-editor"
                                        data-id="2c582a1"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="aug">
                                                On August 14, 2020, The Roland
                                                Torres Votacion Foundation Inc.
                                                donated school essentials for
                                                its 365 scholars in Barangay
                                                Ganatan, Arakan, North Cotabato.
                                                The school packs for Preschool
                                                to High School scholars will aid
                                                them in completing the school
                                                year 2020-2021. In addition, the
                                                budget for the school fees of
                                                its college-level scholars was
                                                also turned-over.
                                              </span>
                                              <br></br>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-730683f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="730683f"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-39309f5 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="39309f5"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-cec4b6e elementor-icon-list--layout-traditional elementor-widget elementor-widget-icon-list"
                                        data-id="cec4b6e"
                                        data-element_type="widget"
                                        data-widget_type="icon-list.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <ul className="elementor-icon-list-items">
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Grade School Pad Paper (for
                                                Grade 1,2,3,4)
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Intermediate Pad Paper (for
                                                Grade 5, 6 Highschool)
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Notebooks
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Color set
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Plastic Envelop with Holder
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Others
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-eb7bde3 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="eb7bde3"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-5f4f3fb elementor-column elementor-col-100 elementor-top-column"
                                  data-id="5f4f3fb"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-680a69e elementor-widget elementor-widget-text-editor"
                                        data-id="680a69e"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="new-norm">
                                                Additional hygiene supplies were
                                                also donated to ensure the
                                                safety of the students and
                                                teachers.
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-20fd5f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="20fd5f8"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-b397a36 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="b397a36"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-e9b8917 elementor-icon-list--layout-traditional elementor-widget elementor-widget-icon-list"
                                        data-id="e9b8917"
                                        data-element_type="widget"
                                        data-widget_type="icon-list.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <ul className="elementor-icon-list-items">
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Face Masks
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Alcohol
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-825e927 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="825e927"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-440faa5 elementor-column elementor-col-50 elementor-top-column"
                                  data-id="440faa5"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-a377a7b elementor-widget elementor-widget-image"
                                        data-id="a377a7b"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-image">
                                            <img
                                              width="960"
                                              height="720"
                                              src="assets/images/RTV-Supplies.jpg"
                                              className="attachment-large size-large"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-6adde95 elementor-column elementor-col-50 elementor-top-column"
                                  data-id="6adde95"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-4532db0 elementor-widget elementor-widget-image"
                                        data-id="4532db0"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-image">
                                            <img
                                              width="960"
                                              height="720"
                                              src="assets/images/RTV-Supplies4.jpg"
                                              className="attachment-large size-large"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-7ac6fa4 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="7ac6fa4"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-a3cbf64 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="a3cbf64"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-ee9c2ec elementor-widget elementor-widget-text-editor"
                                        data-id="ee9c2ec"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="new-norm">
                                                To help our educator in this
                                                trying times, RTV Foundation has
                                                also donated photocopier
                                                machines for Elementary and High
                                                School.
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-e876ad0 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="e876ad0"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-bfb6184 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="bfb6184"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-8fc6f4b elementor-widget elementor-widget-text-editor"
                                        data-id="8fc6f4b"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="konica">
                                                Konica Minolta
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-17e4489 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="17e4489"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-c8da8db elementor-column elementor-col-100 elementor-top-column"
                                  data-id="c8da8db"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-eddf385 elementor-icon-list--layout-traditional elementor-widget elementor-widget-icon-list"
                                        data-id="eddf385"
                                        data-element_type="widget"
                                        data-widget_type="icon-list.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <ul className="elementor-icon-list-items">
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Copier
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Printer
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Colored Scanner
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Network Standard
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Duplex
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Automatic Document Reverse
                                                Feeder
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                2 Drawers and 1 Bypass Tray
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Copy Size: Long, Short, Legal
                                                A4-A5, A3, B4, B5
                                              </span>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                Print Resolution: 600 X 600 DPI
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-1a6988a elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="1a6988a"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-45ed09e elementor-column elementor-col-50 elementor-top-column"
                                  data-id="45ed09e"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-3416e0a elementor-widget elementor-widget-image"
                                        data-id="3416e0a"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-image">
                                            <img
                                              width="1024"
                                              height="768"
                                              src="assets/images/20200814_150331-scaled.jpg"
                                              className="attachment-large size-large"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-47d8903 elementor-column elementor-col-50 elementor-top-column"
                                  data-id="47d8903"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-4283092 elementor-widget elementor-widget-image"
                                        data-id="4283092"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-image">
                                            <img
                                              width="768"
                                              height="552"
                                              src="assets/images/rsz_20200814_152415-1-768x552.jpg"
                                              className="attachment-medium_large size-medium_large"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-b29b26c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="b29b26c"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-f7ca93a elementor-column elementor-col-100 elementor-top-column"
                                  data-id="f7ca93a"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-3efb0ec elementor-widget elementor-widget-text-editor"
                                        data-id="3efb0ec"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="support">
                                                SUPPORT US
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-6125856 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="6125856"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-a45a4cc elementor-column elementor-col-100 elementor-top-column"
                                  data-id="a45a4cc"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-6a1c795 elementor-widget elementor-widget-text-editor"
                                        data-id="6a1c795"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="new-norm">
                                                The Foundation wholeheartedly
                                                thanks the sponsors and donors
                                                who never fall short in
                                                supporting the cause to help
                                                shape the lives of
                                                underprivileged children through
                                                education. And, we ho[e to
                                                extend our services to many
                                                other communities too. These
                                                children continually ask for
                                                assistance, and in the future,
                                                they too will be the ones to
                                                give back to others.
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-41c2d8b elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="41c2d8b"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-971e102 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="971e102"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-a3b8d10 elementor-widget elementor-widget-text-editor"
                                        data-id="a3b8d10"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <NavLink
                                                className="link-internal"
                                                to="/give-a-gift"
                                                data-rich-text-format-boundary="true"
                                              >
                                                Give a Gift{" "}
                                              </NavLink>
                                              <span className="new-norm">
                                                Purchase school essentials to be
                                                donated to the RTVI Scholars
                                              </span>
                                              <br
                                                className="brkline"
                                                data-rich-text-line-break="true"
                                              />
                                              <NavLink
                                                className="sponsor-child"
                                                to="/contact-us"
                                              >
                                                Sponsor A Child{" "}
                                              </NavLink>
                                              <span className="sponsor-school">
                                                Sponsor school fees and other
                                                education-related finances for
                                                as low as 200 pesos per month.
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-16d9136 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="16d9136"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-97cafdf elementor-column elementor-col-100 elementor-top-column"
                                  data-id="97cafdf"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-cf96c79 elementor-widget elementor-widget-text-editor"
                                        data-id="cf96c79"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <span className="support">
                                                MAPYA
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-ec499d4 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="ec499d4"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-35b70b9 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="35b70b9"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-59243c0 elementor-widget elementor-widget-text-editor"
                                        data-id="59243c0"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              <a
                                                className="mapya"
                                                href="https://www.facebook.com/mapyaph"
                                                data-rich-text-format-boundary="true"
                                              >
                                                MAPYA 
                                              </a>&nbsp;
                                              <span className="new-norm">
                                                will be selling locally made
                                                products that are
                                                environment-friendly,
                                                sustainable, and native products
                                                that are proudly crafted by
                                                hand. All proceeds from your
                                                purchase will be donated to the
                                                RTV Foundation to help fund its
                                                scholar&#8217;s education.
                                                Please follow, share, and
                                                purchase MAPYA products.
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <nav
                className="navigation post-navigation"
                role="navigation"
                aria-label="Posts"
              >
                <h2 className="screen-reader-text">Post navigation</h2>
                <div className="nav-links">
                  <div className="nav-previous">
                    <NavLink
                      to="/moving-ahead-with-purpose"
                      rel="prev"
                    >
                      <span className="ast-left-arrow">&larr;</span> Previous
                      Post
                    </NavLink>
                  </div>
                </div>
              </nav>
              {/* <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <h3 id="reply-title" className="comment-reply-title">
                    Leave a Comment{" "}
                    <small>
                      <NavLink
                        rel="nofollow"
                        id="cancel-comment-reply-link"
                        to="/education-in-this-new-normal-time-rtvfi-cares/#respond"
                        style={{ display: "none" }}
                      >
                        Cancel Reply
                      </NavLink>
                    </small>
                  </h3>
                  <form
                    action="wp-comments-post.php"
                    method="post"
                    id="ast-commentform"
                    className="comment-form"
                  >
                    <p className="comment-notes">
                      <span id="email-notes">
                        Your email address will not be published.
                      </span>{" "}
                      Required fields are marked{" "}
                      <span className="required">*</span>
                    </p>
                    <div className="ast-row comment-textarea">
                      <fieldset className="comment-form-comment">
                        <div className="comment-form-textarea ast-col-lg-12">
                          <label for="comment" className="screen-reader-text">
                            Type here..
                          </label>
                          <textarea
                            id="comment"
                            name="comment"
                            placeholder="Type here.."
                            cols="45"
                            rows="8"
                            aria-required="true"
                          ></textarea>
                        </div>
                      </fieldset>
                    </div>
                    <div className="ast-comment-formwrap ast-row">
                      <p className="comment-form-author ast-col-xs-12 ast-col-sm-12 ast-col-md-4 ast-col-lg-4">
                        <label for="author" className="screen-reader-text">
                          Name*
                        </label>
                        <input
                          id="author"
                          name="author"
                          type="text"
                          value=""
                          placeholder="Name*"
                          size="30"
                          aria-required="true"
                        />
                      </p>
                      <p className="comment-form-email ast-col-xs-12 ast-col-sm-12 ast-col-md-4 ast-col-lg-4">
                        <label for="email" className="screen-reader-text">
                          Email*
                        </label>
                        <input
                          id="email"
                          name="email"
                          type="text"
                          value=""
                          placeholder="Email*"
                          size="30"
                          aria-required="true"
                        />
                      </p>
                      <p className="comment-form-url ast-col-xs-12 ast-col-sm-12 ast-col-md-4 ast-col-lg-4">
                        <label for="url">
                          <label for="url" className="screen-reader-text">
                            Website
                          </label>
                          <input
                            id="url"
                            name="url"
                            type="text"
                            value=""
                            placeholder="Website"
                            size="30"
                          />
                        </label>
                      </p>
                    </div>
                    <p className="comment-form-cookies-consent">
                      <input
                        id="wp-comment-cookies-consent"
                        name="wp-comment-cookies-consent"
                        type="checkbox"
                        value="yes"
                      />{" "}
                      <label for="wp-comment-cookies-consent">
                        Save my name, email, and website in this browser for the
                        next time I comment.
                      </label>
                    </p>
                    <p className="form-submit">
                      <input
                        name="submit"
                        type="submit"
                        id="submit"
                        className="submit"
                        value="Post Comment &raquo;"
                      />{" "}
                      <input
                        type="hidden"
                        name="comment_post_ID"
                        value="1779"
                        id="comment_post_ID"
                      />
                      <input
                        type="hidden"
                        name="comment_parent"
                        id="comment_parent"
                        value="0"
                      />
                    </p>
                  </form>
                </div>
              </div> */}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationNewNormal;
