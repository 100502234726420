import "./MovingAhead.css";
import { NavLink } from "react-router-dom";

function MovingAhead() {
  return (
    <div>
      <div id="content" className="site-content site-card-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-1715 post type-post status-publish format-standard hentry category-uncategorized ast-article-single"
                id="post-1715"
              >
                <div className="ast-post-format- ast-no-thumb single-layout-1">
                  <header className="entry-header ast-no-thumbnail">
                    <div className="ast-single-post-order">
                      <h1 className="entry-title" itemProp="headline">
                        Moving Ahead with Purpose
                      </h1>
                      <div className="entry-meta">
                        <span className="posted-on">
                          <span className="published" itemProp="datePublished">
                            {" "}
                            March 9, 2020
                          </span>
                          <span className="updated" itemProp="dateModified">
                            {" "}
                            March 9, 2020
                          </span>
                        </span>{" "}
                        / By admin
                      </div>{" "}
                    </div>
                  </header>

                  <div className="entry-content clear" itemProp="text">
                    <div
                      data-elementor-type="wp-post"
                      data-elementor-id="1715"
                      className="elementor elementor-1715"
                      data-elementor-settings="[]"
                    >
                      <div className="elementor-inner">
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-element elementor-element-bc06b3b elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="bc06b3b"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-68a7542 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="68a7542"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-7e9cea5 elementor-widget elementor-widget-image"
                                        data-id="7e9cea5"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-image">
                                            <img
                                              width="1024"
                                              height="496"
                                              src="assets/images/2020-1st-Assembly-Google-Drive-1024x496.png"
                                              className="attachment-large size-large hoverZoomLink"
                                              alt=""
                                            />{" "}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-3ddcd01 elementor-widget elementor-widget-spacer"
                                        data-id="3ddcd01"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-22f624f elementor-widget elementor-widget-heading"
                                        data-id="22f624f"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <h1 className="elementor-heading-title elementor-size-default">
                                            MOVING AHEAD WITH PURPOSE: RTVFI 1st
                                            General Assembly for 2020
                                          </h1>{" "}
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-f1d61e2 elementor-widget elementor-widget-spacer"
                                        data-id="f1d61e2"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-33cf0f3 elementor-widget elementor-widget-text-editor"
                                        data-id="33cf0f3"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <div>
                                              The Roland Torres Votacion
                                              Foundation Inc. held its 1st
                                              General Assembly last&nbsp;
                                              <span className="font-15">
                                                March 7, 2020, Saturday at
                                                Barangay Ganatan, Arakan,
                                                Cotabato. Attended&nbsp;
                                              </span>
                                              <span className="font-15">
                                                by founder Roland Votacion,
                                                teachers (elementary and high
                                                school), and the&nbsp;
                                              </span>
                                              <span className="font-15">
                                                parents of the scholars who
                                                eagerly participated with the
                                                discussions. The&nbsp;
                                              </span>
                                              <span className="font-15">
                                                foundation supports scholars
                                                from different areas of Barangay
                                                Ganatan. Most&nbsp;
                                              </span>
                                              <span className="font-15">
                                                of them came from the
                                                mountainside which requires them
                                                to travel for hours&nbsp;
                                              </span>
                                              <span className="font-15">
                                                just to attend the event –
                                                talking about persistence!
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-7702f09 elementor-widget elementor-widget-spacer"
                                        data-id="7702f09"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-bc4ca83 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="bc4ca83"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-04c8523 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="04c8523"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-fe1fc6a elementor-widget elementor-widget-heading"
                                        data-id="fe1fc6a"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <h2 className="elementor-heading-title elementor-size-default">
                                            1st General Assembly
                                          </h2>{" "}
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-e5b1beb elementor-widget elementor-widget-text-editor"
                                        data-id="e5b1beb"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <div>
                                              During the assembly, the scholars’
                                              status was highlighted. Many
                                              impeding&nbsp;
                                              <span className="font-15">
                                                issues were discussed as well as
                                                the goals and plans for this
                                                year. RTVFI&nbsp;
                                              </span>
                                              <span className="font-15">
                                                believes that the participation
                                                of the parents and the whole
                                                community is&nbsp;
                                              </span>
                                              <span className="font-15">
                                                vital in sustaining the result
                                                of each program and to deliver a
                                                positive&nbsp;
                                              </span>
                                              <span className="font-15">
                                                outcome.
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-16dffb7 elementor-widget elementor-widget-spacer"
                                        data-id="16dffb7"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-3f8d5f2 elementor-widget elementor-widget-heading"
                                        data-id="3f8d5f2"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <h3 className="elementor-heading-title elementor-size-default">
                                            New Elected Officers
                                          </h3>{" "}
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-b18c206 elementor-widget elementor-widget-text-editor"
                                        data-id="b18c206"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <div>
                                              New RTVFI community leaders were
                                              elected during the assembly. They
                                              will&nbsp;
                                              <span className="font-15">
                                                be the first-hand responders in
                                                monitoring the foundation’s
                                                programs and&nbsp;
                                              </span>
                                              <span className="font-15">
                                                events. The success of the
                                                previous year was all thanks to
                                                the hardworking&nbsp;
                                              </span>
                                              <span className="font-15">
                                                and active outgoing officials –
                                                setting the bars higher for the
                                                newly elected&nbsp;
                                              </span>
                                              <span className="font-15">
                                                officers.
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-de5330e elementor-widget elementor-widget-spacer"
                                        data-id="de5330e"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-c21173a elementor-widget elementor-widget-heading"
                                        data-id="c21173a"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <h3 className="elementor-heading-title elementor-size-default">
                                            2nd Founding Anniversary
                                          </h3>{" "}
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-29a625e elementor-widget elementor-widget-text-editor"
                                        data-id="29a625e"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <section
                                              className="pdf_page"
                                              aria-label="Page 1"
                                            >
                                              <div className="textlayer">
                                                <div>
                                                  On May 23
                                                  <span className="font-15">
                                                    rd&nbsp;
                                                  </span>
                                                  <span className="font-15">
                                                    and 24
                                                  </span>
                                                  <span className="font-15">
                                                    th
                                                  </span>
                                                  <span className="font-15">
                                                    , 2020, RTVFI will celebrate
                                                    its 2nd Founding&nbsp;
                                                  </span>
                                                  <span className="font-15">
                                                    Anniversary. The
                                                    much-awaited event is the
                                                    core activity of the
                                                    foundation&nbsp;
                                                  </span>
                                                  <span className="font-15">
                                                    granting scholarships to the
                                                    underprivileged families
                                                    (especially the&nbsp;
                                                  </span>
                                                  <span className="font-15">
                                                    indigenous people) of Brgy.
                                                    Ganatan. School bags and
                                                    school supplies for
                                                    the&nbsp;
                                                  </span>
                                                  <span className="font-15">
                                                    scholars will also be
                                                    distributed during the
                                                    event. In addition, RTVFI
                                                    will also&nbsp;
                                                  </span>
                                                  <span className="font-15">
                                                    conduct feeding program,
                                                    medical mission, and many
                                                    more. The two-day&nbsp;
                                                  </span>
                                                  <span className="font-15">
                                                    event will also include the
                                                    launching of “Literacy Tree
                                                    Project” participated
                                                    by&nbsp;
                                                  </span>
                                                  <span className="font-15">
                                                    the local community and the
                                                    foundation.
                                                  </span>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-7cd236f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="7cd236f"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-704acdb elementor-column elementor-col-100 elementor-top-column"
                                  data-id="704acdb"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-ecec156 elementor-widget elementor-widget-spacer"
                                        data-id="ecec156"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-7b30eec elementor-widget elementor-widget-heading"
                                        data-id="7b30eec"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <h2 className="elementor-heading-title elementor-size-default">
                                            MAPYA
                                          </h2>{" "}
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-333ed99 elementor-widget elementor-widget-text-editor"
                                        data-id="333ed99"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <div>
                                              <a
                                                className="link-white"
                                                href="https://www.facebook.com/mapyaph"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Mapya
                                              </a>
                                              &nbsp;
                                              <span className="font-15">
                                                comes from a Bagobo term
                                                “MAPIYA” which means GOOD. True
                                                to its&nbsp;
                                              </span>
                                              <span className="font-15">
                                                brand, Mapya aims to help
                                                provide livelihood programs for
                                                the community&nbsp;
                                              </span>
                                              <span className="font-15">
                                                and to help them introduce their
                                                products to the market. Mapya
                                                will be&nbsp;
                                              </span>
                                              <span className="font-15">
                                                launching its products soon –
                                                all made from the talented hands
                                                and&nbsp;
                                              </span>
                                              <span className="font-15">
                                                extraordinary craftsmanship of
                                                the people of Brgy. Ganatan,
                                                Arakan,&nbsp;
                                              </span>
                                              <span className="font-15">
                                                Cotabato. All proceeds will be
                                                donated to RTVFI to sustain its
                                                programs.
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-e74600d elementor-widget elementor-widget-image-gallery"
                                        data-id="e74600d"
                                        data-element_type="widget"
                                        data-widget_type="image-gallery.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-image-gallery">
                                            <div
                                              id="gallery-1"
                                              className="gallery galleryid-1715 gallery-columns-3 gallery-size-shop_catalog"
                                            >
                                              <figure className="gallery-item">
                                                <div className="gallery-icon portrait">
                                                  <a
                                                    data-elementor-open-lightbox="yes"
                                                    data-elementor-lightbox-slideshow="e74600d"
                                                    data-elementor-lightbox-title="prod1"
                                                    href="assets/images/prod1.png"
                                                    className="hoverZoomLink"
                                                  >
                                                    <img
                                                      width="300"
                                                      height="300"
                                                      src="assets/images/prod1-300x300.png"
                                                      className="attachment-shop_catalog size-shop_catalog hoverZoomLink"
                                                      alt=""
                                                    />
                                                  </a>
                                                </div>
                                              </figure>
                                              <figure className="gallery-item">
                                                <div className="gallery-icon landscape">
                                                  <a
                                                    data-elementor-open-lightbox="yes"
                                                    data-elementor-lightbox-slideshow="e74600d"
                                                    data-elementor-lightbox-title="prod2"
                                                    href="assets/images/prod2.png"
                                                    className="hoverZoomLink"
                                                  >
                                                    <img
                                                      width="300"
                                                      height="300"
                                                      src="assets/images/prod2-300x300.png"
                                                      className="attachment-shop_catalog size-shop_catalog hoverZoomLink"
                                                      alt=""
                                                    />
                                                  </a>
                                                </div>
                                              </figure>
                                              <figure className="gallery-item">
                                                <div className="gallery-icon landscape">
                                                  <a
                                                    data-elementor-open-lightbox="yes"
                                                    data-elementor-lightbox-slideshow="e74600d"
                                                    data-elementor-lightbox-title="prod3"
                                                    href="assets/images/prod3.png"
                                                    className="hoverZoomLink"
                                                  >
                                                    <img
                                                      width="300"
                                                      height="300"
                                                      src="assets/images/prod3-300x300.png"
                                                      className="attachment-shop_catalog size-shop_catalog hoverZoomLink"
                                                      alt=""
                                                    />
                                                  </a>
                                                </div>
                                              </figure>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-c2a6398 elementor-widget elementor-widget-spacer"
                                        data-id="c2a6398"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-element elementor-element-f5490a9 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                            data-id="f5490a9"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div
                                  className="elementor-element elementor-element-863c909 elementor-column elementor-col-100 elementor-top-column"
                                  data-id="863c909"
                                  data-element_type="column"
                                >
                                  <div className="elementor-column-wrap  elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div
                                        className="elementor-element elementor-element-9af332b elementor-widget elementor-widget-heading"
                                        data-id="9af332b"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <h2 className="elementor-heading-title elementor-size-default">
                                            SUPPORT US
                                          </h2>{" "}
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-8a0cf47 elementor-widget elementor-widget-text-editor"
                                        data-id="8a0cf47"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-text-editor elementor-clearfix">
                                            <p>
                                              All hands on deck! Please support
                                              our cause. Be one of our&nbsp;
                                              <NavLink to="/donate">
                                                <u>sponsors</u>
                                              </NavLink>
                                              &nbsp;or&nbsp;
                                              <u>
                                                <NavLink to="/give-a-gift">
                                                  send a gift
                                                </NavLink>
                                              </u>
                                              &nbsp;to our scholars. Contact us
                                              now to know
                                              more.&nbsp;Follow&nbsp;
                                              <u>
                                                <a href="https://www.facebook.com/mapyaph">
                                                  MAPYA
                                                </a>
                                              </u>
                                              &nbsp;for locally made products.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-element elementor-element-4e50162 elementor-widget elementor-widget-spacer"
                                        data-id="4e50162"
                                        data-element_type="widget"
                                        data-widget_type="spacer.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <nav
                className="navigation post-navigation"
                role="navigation"
                aria-label="Posts"
              >
                <h2 className="screen-reader-text">Post navigation</h2>
                <div className="nav-links">
                  <div className="nav-previous">
                    <NavLink
                      to="/school-bags-giving"
                      rel="prev"
                    >
                      <span className="ast-left-arrow">←</span> Previous Post
                    </NavLink>
                  </div>
                  <div className="nav-next">
                    <NavLink
                      to="/education-in-this-new-normal-time-rtvfi-cares"
                      rel="next"
                    >
                      Next Post <span className="ast-right-arrow">→</span>
                    </NavLink>
                  </div>
                </div>
              </nav>
              <div id="comments" className="comments-area"></div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MovingAhead;
