import { useState } from 'react';
import { ProductAPI } from '../../apis/productAPI';
import './ContactForm.css';

function ContactForm() {
  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [subject, setSubject] = useState(``);
  const [message, setMessage] = useState(``);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    // var bodyFormData = new FormData();
    // bodyFormData.append("name", name);
    // bodyFormData.append("email", email);
    // bodyFormData.append("subject", subject);
    // bodyFormData.append("message", message);

    const params = {
      name,
      email,
      subject,
      message,
    };

    setIsDisabled(true);

    ProductAPI.contact(params).then((res) => {
      setIsDisabled(false);
      setIsSubmitted(true);
      localStorage.removeItem(`cartItems`);
      console.log('res :>> ', res);
    });
  };

  return (
    <div>
      <form
        onSubmit={(e) => submitForm(e)}
        className='wpcf7-form init'
        encType='multipart/form-data'
      >
        <p>
          <label>
            Your Name (required)
            <span className='wpcf7-form-control-wrap your-name'>
              <input
                type='text'
                name='your-name'
                size='40'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                className='wpcf7-form-control wpcf7-text wpcf7-validates-as-required'
                aria-required='true'
                aria-invalid='false'
              />
            </span>
          </label>
          <br />
          <label>
            Your Email (required)
            <span className='wpcf7-form-control-wrap your-email'>
              <input
                type='email'
                name='your-email'
                size='40'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email'
                aria-required='true'
                aria-invalid='false'
              />
            </span>
          </label>
        </p>
        <p>
          <label>
            Subject
            <span className='wpcf7-form-control-wrap your-contact'>
              <input
                type='text'
                name='your-contact'
                size='40'
                className='wpcf7-form-control wpcf7-text'
                aria-invalid='false'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </span>
          </label>
        </p>
        <p>
          <label>
            Your Message
            <br />
            <span className='wpcf7-form-control-wrap your-message'>
              <textarea
                name='your-message'
                cols='40'
                rows='10'
                className='wpcf7-form-control wpcf7-textarea'
                aria-invalid='false'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </span>
          </label>
        </p>
        <p>
          <input
            type='submit'
            disabled={isDisabled || isSubmitted}
            value={
              isSubmitted
                ? 'Sent. Thank you!'
                : isDisabled
                ? 'Sending...'
                : 'Send'
            }
            className='wpcf7-form-control wpcf7-submit'
          />
        </p>
        <div
          className='wpcf7-response-output'
          role='alert'
          aria-hidden='true'
        ></div>
      </form>
    </div>
  );
}

export default ContactForm;
