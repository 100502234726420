import * as React from "react";
import { useLayoutEffect } from 'react';

import { Routes, Route, useLocation  } from "react-router-dom";
import "./App.css";
import AboutUs from "./components/AboutUs/AboutUs";
import Cart from "./components/Cart/Cart";
import Checkout from "./components/Checkout/Checkout";
import ContactUs from "./components/ContactUs/ContactUs";
import Donate from "./components/Donate/Donate";
import GiveAGift from "./components/GiveAGift/GiveAGift";
import Home from "./components/Home/Home";
import Layout from "./components/Layout/Layout";
import BagsGiving from "./components/News/BagsGiving/BagsGiving";
import EducationNewNormal from "./components/News/EducationNewNormal/EducationNewNormal";
import MovingAhead from "./components/News/MovingAhead/MovingAhead";
import SuppliesGiving from "./components/News/SuppliesGiving/SuppliesGiving";
import NewsEvents from "./components/NewsEvents/NewsEvents";
import NothingHere from "./components/NothingHere/NothingHere";
import SupportUs from "./components/SupportUs/SupportUs";

function App() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}

        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="support-us" element={<SupportUs />} />
            <Route path="give-a-gift" element={<GiveAGift />} />
            <Route path="donate" element={<Donate />} />
            <Route path="news-events" element={<NewsEvents />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="cart" element={<Cart />} />
            <Route path="education-in-this-new-normal-time-rtvfi-cares" element={<EducationNewNormal />} />
            <Route path="moving-ahead-with-purpose" element={<MovingAhead />} />
            <Route path="school-bags-giving" element={<BagsGiving />} />
            <Route path="school-supplies-giving" element={<SuppliesGiving />} />
            <Route path="checkout" element={<Checkout />} />

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<NothingHere />} />
          </Route>
        </Routes>
    </div>
  );
}

export default App;
