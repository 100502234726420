import { NavLink } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <div>
      <footer className="site-footer" id="colophon">
        <div className="footer-adv footer-adv-layout-4">
          <div className="footer-adv-overlay">
            <div className="ast-container">
              <div className="ast-row">
                <div className="ast-col-lg-3 ast-col-md-3 ast-col-sm-12 ast-col-xs-12 footer-adv-widget footer-adv-widget-1">
                  <div id="pages-2" className="widget widget_pages">
                    <h2 className="widget-title">HELPFUL LINK</h2>
                    <ul>
                      <li className="page_item page-item-1058 menu-item">
                        <NavLink to="about-us">About Us</NavLink>
                      </li>
                      <li className="page_item page-item-16 menu-item">
                        <NavLink to="contact-us">Contact Us</NavLink>
                      </li>
                      <li className="page_item page-item-1088 menu-item">
                        <NavLink to="give-a-gift">Give a Gift</NavLink>
                      </li>
                      <li className="page_item page-item-1092 menu-item">
                        <NavLink to="news-events">News &#038; Events</NavLink>
                      </li>
                      <li className="page_item page-item-1078 menu-item">
                        <NavLink to="support-us">Support Us​</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ast-col-lg-3 ast-col-md-3 ast-col-sm-12 ast-col-xs-12 footer-adv-widget footer-adv-widget-2">
                  <div id="text-4" className="widget widget_text">
                    <h2 className="widget-title">ADDRESS</h2>
                    <div className="textwidget">
                      <p>
                        <i id="logo-size" className="fas fa-map-marker-alt">
                          {" "}
                        </i>{" "}
                        Dev Hub, 4th Floor, Amalgated Capital Bldg.,
                        <br />
                        J. P. Laurel Ave. cor. Iñigo Street, Bajada, Davao City.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ast-col-lg-3 ast-col-md-3 ast-col-sm-12 ast-col-xs-12 footer-adv-widget footer-adv-widget-3">
                  <div id="text-2" className="widget widget_text">
                    <h2 className="widget-title">CONTACT US</h2>
                    <div className="textwidget">
                      <p>
                        <i
                          id="logo-size"
                          className="fa fa-phone"
                          aria-hidden="true"
                        >
                          {" "}
                        </i>{" "}
                        (082) 224 7793
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ast-col-lg-3 ast-col-md-3 ast-col-sm-12 ast-col-xs-12 footer-adv-widget footer-adv-widget-4">
                  <div id="text-3" className="widget widget_text">
                    <h2 className="widget-title">CONNECT WITH US</h2>
                    <div className="textwidget">
                      <div className="textwidget">
                        <div _ngcontent-c5="" className="row">
                          <div _ngcontent-c5="" className="col-12">
                            <p>
                              <a
                                href="https://www.facebook.com/RTVFoundation"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i
                                  _ngcontent-c5=""
                                  className="fab fa-facebook-f"
                                  id="cn-logo"
                                ></i>
                              </a>
                              <a
                                href="https://twitter.com/rtv_foundation"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i
                                  _ngcontent-c5=""
                                  className="fab fa-twitter"
                                  id="cn-logo"
                                ></i>
                              </a>
                              <a
                                href="https://ph.linkedin.com/company/rtvfoundation"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i
                                  _ngcontent-c5=""
                                  className="fab fa-linkedin-in"
                                  id="cn-logo"
                                ></i>
                              </a>
                              <a
                                href="https://www.instagram.com/rtvfoundation/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i
                                  _ngcontent-c5=""
                                  className="fab fa-instagram"
                                  id="cn-logo"
                                ></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ast-small-footer footer-sml-layout-2">
          <div className="ast-footer-overlay">
            <div className="ast-container">
              <div className="ast-small-footer-wrap">
                <div className="ast-row ast-flex">
                  <div className="ast-small-footer-section ast-small-footer-section-1 ast-small-footer-section-equally ast-col-md-6 ast-col-xs-12">
                    Copyright © 2022{" "}
                    <span className="ast-footer-site-title">
                      RTV Foundation
                    </span>
                    , Inc. All RIghts Reserved.{" "}
                  </div>

                  <div className="ast-small-footer-section ast-small-footer-section-2 ast-small-footer-section-equally ast-col-md-6 ast-col-xs-12">
                    Privacy &amp; Legal{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
