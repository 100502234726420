import { Link } from "react-router-dom";
import ContactForm from "../ContactForm/ContactForm";
import "./ContactUs.css";

function ContactUs() {
  return (
    <div>
      <div id="content" className="site-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-16 page type-page status-publish ast-article-single"
                id="post-16"
              >
                <header className="entry-header ast-header-without-markup"></header>
                <div className="entry-content clear">
                  <div
                    data-elementor-type="wp-post"
                    data-elementor-id="16"
                    className="elementor elementor-16"
                    data-elementor-settings="[]"
                  >
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <section
                          className="elementor-element elementor-element-ef418b4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="ef418b4"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-background-overlay"></div>
                          <div className="elementor-container elementor-column-gap-wide">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-2556a46 elementor-column elementor-col-100 elementor-top-column"
                                data-id="2556a46"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-202700df elementor-widget elementor-widget-spacer"
                                      data-id="202700df"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-a19080f elementor-widget elementor-widget-heading"
                                      data-id="a19080f"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          Contact Us
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-33e781a0 elementor-widget elementor-widget-divider"
                                      data-id="33e781a0"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-0944c31 elementor-widget elementor-widget-heading"
                                      data-id="0944c31"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-heading-title elementor-size-default">
                                          We appreciate your interest in our
                                          mission and advocacy of transforming
                                          the lives of the most needy students
                                          in the remote areas to have quality
                                          education and family economic
                                          stability.
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-18e3e09f elementor-widget elementor-widget-spacer"
                                      data-id="18e3e09f"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-435e67e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="435e67e"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-c6de513 elementor-column elementor-col-100 elementor-top-column"
                                data-id="c6de513"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap">
                                  <div className="elementor-widget-wrap"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-9920f85 elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="9920f85"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-wide">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-ee330f0 elementor-column elementor-col-50 elementor-top-column"
                                data-id="ee330f0"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-e04da06 elementor-widget elementor-widget-spacer"
                                      data-id="e04da06"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-6c85b8f elementor-widget elementor-widget-heading"
                                      data-id="6c85b8f"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          We Would Love to Hear from You
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-c932149 elementor-widget elementor-widget-text-editor"
                                      data-id="c932149"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <p>
                                            If you have any inquiries, please
                                            send us a message.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-0c7b3e4 elementor-widget elementor-widget-spacer"
                                      data-id="0c7b3e4"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-ab9b970 elementor-widget elementor-widget-heading"
                                      data-id="ab9b970"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h6 className="elementor-heading-title elementor-size-default">
                                          Address
                                        </h6>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-787d818 elementor-widget elementor-widget-text-editor"
                                      data-id="787d818"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <i
                                            id="logo-size"
                                            className="fas fa-map-marker-alt"
                                          >
                                            {" "}
                                          </i>
                                          Dev Hub, 4th Floor, Amalgated Capital
                                          Bldg. (former Conpinco Bldg.), J. P.
                                          Laurel Ave. cor. Iñigo Street, Bajada,
                                          Davao City, Philippines 8000
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-8455c74 elementor-widget elementor-widget-heading"
                                      data-id="8455c74"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h6 className="elementor-heading-title elementor-size-default">
                                          Contact
                                        </h6>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-b7519d0 elementor-icon-list--layout-traditional elementor-widget elementor-widget-icon-list"
                                      data-id="b7519d0"
                                      data-element_type="widget"
                                      data-widget_type="icon-list.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <ul className="elementor-icon-list-items">
                                          <li className="elementor-icon-list-item">
                                            <Link to="#/">
                                              {" "}
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-phone"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                (082) 224 – 7793{" "}
                                              </span>
                                            </Link>
                                          </li>
                                          <li className="elementor-icon-list-item">
                                            <Link to="#/">
                                              {" "}
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-phone"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                (639) 933 – 378 – 5250
                                              </span>
                                            </Link>
                                          </li>
                                          <li className="elementor-icon-list-item">
                                            <a href="mailto:rtvfoundation0925@gmail.com">
                                              {" "}
                                              <span className="elementor-icon-list-icon">
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-envelope"
                                                ></i>
                                              </span>
                                              <span className="elementor-icon-list-text">
                                                rtvfoundation0925@gmail.com
                                              </span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-e136416 elementor-widget elementor-widget-spacer"
                                      data-id="e136416"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-4e92a85 elementor-widget elementor-widget-heading"
                                      data-id="4e92a85"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h6 className="elementor-heading-title elementor-size-default">
                                          Keep In Touch
                                        </h6>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-5997d79 elementor-shape-circle elementor-widget elementor-widget-social-icons"
                                      data-id="5997d79"
                                      data-element_type="widget"
                                      data-widget_type="social-icons.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-social-icons-wrapper">
                                          <a
                                            className="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-repeater-item-08c8c60"
                                            rel="noreferrer"
                                            href="https://www.facebook.com/rtvfoundation/"
                                            target="_blank"
                                          >
                                            <span className="elementor-screen-only">
                                              Facebook-f
                                            </span>
                                            <i className="fab fa-facebook-f"></i>
                                          </a>
                                          <a
                                            className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-9a69e1d"
                                            rel="noreferrer"
                                            href="https://twitter.com/rtv_foundation"
                                            target="_blank"
                                          >
                                            <span className="elementor-screen-only">
                                              Twitter
                                            </span>
                                            <i className="fab fa-twitter"></i>{" "}
                                          </a>
                                          <a
                                            className="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-repeater-item-4986901"
                                            rel="noreferrer"
                                            href="https://ph.linkedin.com/company/rtvfoundation"
                                            target="_blank"
                                          >
                                            <span className="elementor-screen-only">
                                              Linkedin
                                            </span>
                                            <i className="fab fa-linkedin"></i>{" "}
                                          </a>
                                          <a
                                            className="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-3608fc6"
                                            rel="noreferrer"
                                            href="https://www.instagram.com/rtvfoundation/"
                                            target="_blank"
                                          >
                                            <span className="elementor-screen-only">
                                              Instagram
                                            </span>
                                            <i className="fab fa-instagram"></i>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-d72d16d elementor-column elementor-col-50 elementor-top-column"
                                data-id="d72d16d"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-f915551 elementor-widget elementor-widget-spacer"
                                      data-id="f915551"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-ec9f039 elementor-widget elementor-widget-heading"
                                      data-id="ec9f039"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          Contact Us
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-6b698c7 elementor-widget elementor-widget-text-editor"
                                      data-id="6b698c7"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <div>
                                            <br />
                                            <div
                                              role="form"
                                              className="wpcf7"
                                              id="wpcf7-f1204-p16-o1"
                                              lang="en-US"
                                              dir="ltr"
                                            >
                                              <div
                                                className="screen-reader-response"
                                                role="alert"
                                                aria-live="polite"
                                              ></div>
                                              <ContactForm></ContactForm>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-772c9f9 elementor-widget elementor-widget-spacer"
                                      data-id="772c9f9"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
