import CheckoutForm from "../CheckoutForm/CheckoutForm";
import "./Donate.css";

function Donate() {
  return (
    <div>
      <div id="content" className="site-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-103 page type-page status-publish ast-article-single"
                id="post-103"
              >
                <header className="entry-header ast-header-without-markup"></header>
                <div className="entry-content clear">
                  <div
                    data-elementor-type="wp-post"
                    data-elementor-id="103"
                    className="elementor elementor-103"
                    data-elementor-settings="[]"
                  >
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <section
                          className="elementor-element elementor-element-41724257 elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="41724257"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-background-overlay"></div>
                          <div className="elementor-container elementor-column-gap-wide">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-72243ff9 elementor-column elementor-col-100 elementor-top-column"
                                data-id="72243ff9"
                                data-element_type="column"
                                data-settings='{"background_background":"classic"}'
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-f9dd4c4 elementor-widget elementor-widget-spacer"
                                      data-id="f9dd4c4"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-2cb87c91 elementor-widget elementor-widget-heading"
                                      data-id="2cb87c91"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-heading-title elementor-size-default">
                                          THANK YOU FOR YOUR SUPPORT
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-6a0bc6f elementor-widget elementor-widget-spacer"
                                      data-id="6a0bc6f"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-element elementor-element-d7a9757 elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="d7a9757"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-2492ff7 elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="2492ff7"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-6bf0b5e elementor-widget elementor-widget-heading"
                                                  data-id="6bf0b5e"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                      Donation Options
                                                    </h2>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-0db7911 elementor-absolute elementor-widget elementor-widget-text-editor"
                                                  data-id="0db7911"
                                                  data-element_type="widget"
                                                  data-settings='{"_position":"absolute"}'
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        Visit any branch of
                                                        these banks near you,
                                                        write the following
                                                        details into the deposit
                                                        slip or terminal:
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-1fa12ca elementor-widget elementor-widget-spacer"
                                                  data-id="1fa12ca"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-8a6ad3b elementor-widget elementor-widget-text-editor"
                                                  data-id="8a6ad3b"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <div>
                                                        <img
                                                          alt=""
                                                          className="bank-logo"
                                                          src={"assets/images/bpi.png"}
                                                          width="115"
                                                          height="58"
                                                        />
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-6 col-sm-12">
                                                          <div className="bank-type">
                                                            <b>
                                                              Online / Mobile
                                                              Cash Transfer
                                                            </b>
                                                          </div>
                                                          <ul className="mobile-info">
                                                            <li>
                                                              Log-in at BPI
                                                              Express Mobile
                                                              App, then click
                                                              the <b>Transfer</b> icon, then <b>
                                                                Transfer to
                                                                Anyone
                                                              </b>
                                                            </li>
                                                            <li>
                                                              Then enter the
                                                              following details:
                                                              <ul>
                                                                <li>
                                                                  To Account:
                                                                  9409-1016-04
                                                                </li>
                                                                <li>
                                                                  (Account Name:
                                                                  Roland T.
                                                                  Votacion)
                                                                </li>
                                                              </ul>
                                                            </li>
                                                            <li>
                                                              Click <b>Transfer</b> then <b>Confirm</b>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                          <div className="bank-type"></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-ec72439 elementor-widget elementor-widget-text-editor"
                                                  data-id="ec72439"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <div>
                                                        <div>
                                                          <img
                                                            alt=""
                                                            className="bank-logo"
                                                            src={"assets/images/bdo.png"}
                                                            width="119"
                                                            height="58"
                                                          />
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-md-6 col-sm-12">
                                                            <div className="bank-type">
                                                              <strong>
                                                                Online / Mobile
                                                                Cash Transfer
                                                              </strong>
                                                            </div>
                                                            <ul className="mobile-info">
                                                              <li>
                                                                Log-in at BDO
                                                                Personal Mobile
                                                                App, then click
                                                                the <b>Menu</b> icon, then <b>
                                                                  Send Money
                                                                </b>, then <b>
                                                                  To any BDO
                                                                  Account
                                                                </b>
                                                              </li>
                                                              <li>
                                                                Then enter the
                                                                following
                                                                details:
                                                                <ul>
                                                                  <li>
                                                                    Destination
                                                                    Account:
                                                                    0038-0020-3205
                                                                  </li>
                                                                  <li>
                                                                    (Account
                                                                    Name: Roland
                                                                    T. Votacion)
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                Click <b>Transfer</b> then <b>Confirm</b>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-8ccf16b elementor-widget elementor-widget-text-editor"
                                                  data-id="8ccf16b"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <div>
                                                        <img
                                                          alt=""
                                                          className="bank-logo"
                                                          src={"assets/images/eastwest.png"}
                                                          width="143"
                                                          height="72"
                                                        />
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-6 col-sm-12">
                                                          <div className="bank-type">
                                                            <strong>
                                                              Online / Mobile
                                                              Cash Transfer
                                                            </strong>
                                                          </div>
                                                          <ul className="mobile-info">
                                                            <li>
                                                              Log-in at EastWest
                                                              Online Banking,
                                                              then click the <b>Accounts</b> menu, <b>
                                                                Make a Transfer</b>
                                                              , <b>Send Money</b>, <b>
                                                                then One Time Payment
                                                              </b>
                                                            </li>
                                                            <li>
                                                              Then enter the
                                                              following details:
                                                              <ul>
                                                                <li>
                                                                  Destination
                                                                  Account:
                                                                  2000-3456-7661
                                                                </li>
                                                                <li>
                                                                  (Account Name:
                                                                  Roland Torres
                                                                  Foundation,
                                                                  Inc.)
                                                                </li>
                                                              </ul>
                                                            </li>
                                                            <li>
                                                              Click <b>Transfer</b> then <b>Confirm</b>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-63f5ca9 elementor-widget elementor-widget-text-editor"
                                                  data-id="63f5ca9"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <div>
                                                        <img
                                                          alt=""
                                                          className="bank-logo"
                                                          src={"assets/images/paypal.png"}
                                                          width="153"
                                                          height="58"
                                                        />
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-12">
                                                          <div className="bank-type">
                                                            <strong>
                                                              Online / Mobile
                                                              Cash Transfer
                                                            </strong>
                                                          </div>
                                                          <ul className="mobile-info">
                                                            <li>
                                                              Log-in at PayPal
                                                              website, then
                                                              click the <b>
                                                                Send Payment
                                                              </b> at the menu
                                                            </li>
                                                            <li>
                                                              Then enter the
                                                              following details:
                                                              <ul>
                                                                <li>
                                                                  To (e-mail): <b>
                                                                    rolandvotacion24@gmail.com
                                                                  </b>
                                                                </li>
                                                                <li>
                                                                  (Account Name:
                                                                  Roland T.
                                                                  Votacion)
                                                                </li>
                                                                <li>
                                                                  What is the
                                                                  purpose of
                                                                  this payment?: <b>
                                                                    I&#8217;m
                                                                    making
                                                                    personal
                                                                    payments
                                                                  </b> (i.e.,
                                                                  donations,
                                                                  shared
                                                                  expenses,
                                                                  rental
                                                                  charges,
                                                                  payment and
                                                                  others)
                                                                </li>
                                                                <li>
                                                                  (Account Name:
                                                                  Roland T.
                                                                  Votacion)
                                                                </li>
                                                              </ul>
                                                            </li>
                                                            <li>
                                                              Click <b>
                                                                Send Payment
                                                              </b>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-cb63942 elementor-widget elementor-widget-text-editor"
                                                  data-id="cb63942"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <div>
                                                        <img
                                                          alt=""
                                                          className="bank-logo"
                                                          src={"assets/images/payoneer.png"}
                                                          width="151"
                                                          height="58"
                                                        />
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-12">
                                                          <div className="bank-type">
                                                            <strong>
                                                              Online / Mobile
                                                              Cash Transfer
                                                            </strong>
                                                          </div>
                                                          <ul className="mobile-info">
                                                            <li>
                                                              Log-in at Payoneer
                                                              website, then
                                                              click the <b>Pay menu</b> then, <b>
                                                                Make a Payment
                                                              </b>
                                                            </li>
                                                            <li>
                                                              Then enter the
                                                              following details:
                                                              <ul>
                                                                <li>
                                                                  To (e-mail): <b>
                                                                    rolandvotacion30@gmail.com
                                                                  </b>
                                                                </li>
                                                                <li>
                                                                  (Account Name:
                                                                  Roland T.
                                                                  Votacion)
                                                                </li>
                                                                <li>
                                                                  What is the
                                                                  purpose of
                                                                  this payment?: <b>
                                                                    I&#8217;m
                                                                    making
                                                                    personal
                                                                    payments
                                                                  </b> (i.e.,
                                                                  Donations)
                                                                </li>
                                                              </ul>
                                                            </li>
                                                            <li>
                                                              Click <b>
                                                                Send Payment
                                                              </b>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-c588695 elementor-widget elementor-widget-spacer"
                                                  data-id="c588695"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-8261bf3 elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="8261bf3"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-b121a97 elementor-widget elementor-widget-spacer"
                                                  data-id="b121a97"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-5555f07 elementor-widget elementor-widget-text-editor"
                                                  data-id="5555f07"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <div>
                                                        <b style={{fontStyle: 'inherit'}}>
                                                          Bank Deposit
                                                        </b>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-6 col-sm-12">
                                                          <ul>
                                                            <li className="info">
                                                              BPI (Bank of the
                                                              Philippine
                                                              Islands)
                                                            </li>
                                                            <li className="info">
                                                              Account Name:
                                                              Roland T. Votacion
                                                            </li>
                                                            <li className="info">
                                                              Account Number:
                                                              9409 &#8211; 1016
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-201a562 elementor-widget elementor-widget-spacer"
                                                  data-id="201a562"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-7ca7a39 elementor-widget elementor-widget-text-editor"
                                                  data-id="7ca7a39"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <div className="bank-type">
                                                        <b>Bank Deposit</b>
                                                      </div>
                                                      <ul>
                                                        <li className="info">
                                                          BDO (Banco de Oro)
                                                        </li>
                                                        <li className="info">
                                                          Account Name: Roland
                                                          T. Votacion
                                                        </li>
                                                        <li className="info">
                                                          Account Number: 0038
                                                          &#8211; 0020 &#8211;
                                                          3205
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-0adde57 elementor-widget elementor-widget-spacer"
                                                  data-id="0adde57"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-e95f50d elementor-widget elementor-widget-text-editor"
                                                  data-id="e95f50d"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <div className="bank-type">
                                                        <strong>
                                                          Bank Deposit
                                                        </strong>
                                                      </div>
                                                      <ul>
                                                        <li className="info">
                                                          Eastwest Bank
                                                        </li>
                                                        <li className="info">
                                                          Account Name: Roland
                                                          Torres Foundation,
                                                          Inc.
                                                        </li>
                                                        <li className="info">
                                                          Account Number: 2000
                                                          &#8211; 3456 &#8211;
                                                          7661
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-f88eb9b elementor-widget elementor-widget-text-editor"
                                                  data-id="f88eb9b"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix"></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-71e75e2 elementor-column elementor-col-33 elementor-inner-column"
                                            data-id="71e75e2"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-e891e34 elementor-widget elementor-widget-spacer"
                                                  data-id="e891e34"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-4be2b74 elementor-widget elementor-widget-spacer"
                                                  data-id="4be2b74"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-9c07a92 elementor-widget elementor-widget-heading"
                                                  data-id="9c07a92"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                      GIVE THE GIFT OF EDUCATION
                                                    </h2>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-97dabc1 elementor-widget elementor-widget-spacer"
                                                  data-id="97dabc1"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-7256b5c elementor-widget elementor-widget-heading"
                                                  data-id="7256b5c"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-heading-title elementor-size-default">
                                                      Choose your type of
                                                      donation:
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-5ccc067 elementor-tabs-view-horizontal elementor-widget elementor-widget-tabs"
                                                  data-id="5ccc067"
                                                  data-element_type="widget"
                                                  data-widget_type="tabs.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div
                                                      className="elementor-tabs"
                                                      role="tablist"
                                                    >
                                                      <div className="elementor-tabs-wrapper">
                                                        <div
                                                          id="elementor-tab-title-9731"
                                                          className="elementor-tab-title elementor-tab-desktop-title"
                                                          data-tab="1"
                                                          role="tab"
                                                          aria-controls="elementor-tab-content-9731"
                                                        >
                                                          <a href="/" onClick={(e) => e.preventDefault()}>
                                                            Elementary / High
                                                            School
                                                          </a>
                                                        </div>
                                                        <div
                                                          id="elementor-tab-title-9732"
                                                          className="elementor-tab-title elementor-tab-desktop-title"
                                                          data-tab="2"
                                                          role="tab"
                                                          aria-controls="elementor-tab-content-9732"
                                                        >
                                                          <a href="/" onClick={(e) => e.preventDefault()}>College</a>
                                                        </div>
                                                      </div>
                                                      <div className="elementor-tabs-content-wrapper">
                                                        <div
                                                          className="elementor-tab-title elementor-tab-mobile-title"
                                                          data-tab="1"
                                                          role="tab"
                                                        >
                                                          Elementary / High
                                                          School
                                                        </div>
                                                        <div
                                                          id="elementor-tab-content-9731"
                                                          className="elementor-tab-content elementor-clearfix"
                                                          data-tab="1"
                                                          role="tabpanel"
                                                          aria-labelledby="elementor-tab-title-9731"
                                                        >
                                                          <div className="custom-control custom-radio custom-control-inline ">
                                                            <label
                                                              className="custom-control-label"
                                                              htmlFor="defaultInline1"
                                                            >
                                                              200 / monthly or
                                                            </label> <span style={{fontSize: '15px'}}>
                                                              1200 / annually
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="elementor-tab-title elementor-tab-mobile-title"
                                                          data-tab="2"
                                                          role="tab"
                                                        >
                                                          College
                                                        </div>
                                                        <div
                                                          id="elementor-tab-content-9732"
                                                          className="elementor-tab-content elementor-clearfix"
                                                          data-tab="2"
                                                          role="tabpanel"
                                                          aria-labelledby="elementor-tab-title-9732"
                                                        >
                                                          <div className="custom-control custom-radio custom-control-inline ">
                                                            <label
                                                              className="custom-control-label"
                                                              htmlFor="defaultInline1"
                                                            >
                                                              1000 / monthly or
                                                            </label>
                                                            <span style={{fontSize: '15px'}}>
                                                              12000 / annually
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-99b451f elementor-widget elementor-widget-text-editor"
                                                  data-id="99b451f"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        After depositing /
                                                        sending your payment,
                                                        please confirm your
                                                        payment to us by sending
                                                        a scanned copy or photo
                                                        of the receipt).
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-277e5a1 elementor-widget elementor-widget-shortcode"
                                                  data-id="277e5a1"
                                                  data-element_type="widget"
                                                  data-widget_type="shortcode.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-shortcode">
                                                      <div
                                                        role="form"
                                                        className="wpcf7"
                                                        id="wpcf7-f1276-p103-o1"
                                                        lang="en-US"
                                                        dir="ltr"
                                                      >
                                                        <div
                                                          className="screen-reader-response"
                                                          role="alert"
                                                          aria-live="polite"
                                                        ></div>

                                                        <CheckoutForm isDonate={true}></CheckoutForm>

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <div
                                      className="elementor-element elementor-element-2fc94ae9 elementor-widget elementor-widget-spacer"
                                      data-id="2fc94ae9"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-6063178 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="6063178"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-d5554c2 elementor-column elementor-col-100 elementor-top-column"
                                data-id="d5554c2"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donate;
