import "./BagsGiving.css";
import { NavLink } from "react-router-dom";

function BagsGiving() {
  return (
    <div>
      <div id="content" className="site-content site-card-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-1715 post type-post status-publish format-standard hentry category-uncategorized ast-article-single"
                id="post-1715"
              >
                <div className="ast-post-format- ast-no-thumb single-layout-1">
                  <header className="entry-header ast-no-thumbnail">
                    <div className="ast-single-post-order">
                      <h1 className="entry-title" itemProp="headline">
                        SCHOOL BAGS GIVING
                      </h1>
                      <div className="entry-meta">
                        <span className="posted-on">
                          <span className="published" itemProp="datePublished">
                            {" "}
                            February 3, 2020
                          </span>
                          <span className="updated" itemProp="dateModified">
                            {" "}
                            February 3, 2020
                          </span>
                        </span>{" "}
                        / By admin
                      </div>{" "}
                    </div>
                  </header>

                  <div className="entry-content clear" itemProp="text">
                    <p>
                      School bags and other equipment to support schooling have
                      become a very essential need for children attending
                      school. School bags gives children a sense of pride and
                      help them to keep their school supplies sorted. It also
                      helps them to feel comfortable with their things while
                      they are studying. The Roland Torres Votacion Foundation,
                      Inc., donates school bags and distribute to them as the
                      school reopens. A school bag can be also used for four to
                      five years while a good Calculator can also be used for
                      years. Buying a school shoe can also take a child at least
                      two years. The high impact is that these children will
                      feel a touch of care and that there are some people out
                      there who cares about them. This will encourage them to be
                      studious.
                    </p>
                  </div>
                </div>
              </article>

              <nav
                className="navigation post-navigation"
                role="navigation"
                aria-label="Posts"
              >
                <h2 className="screen-reader-text">Post navigation</h2>
                <div className="nav-links">
                  <div className="nav-previous">
                    <NavLink to="/school-supplies-giving" rel="prev">
                      <span className="ast-left-arrow">←</span> Previous Post
                    </NavLink>
                  </div>
                  <div className="nav-next">
                    <NavLink to="/moving-ahead-with-purpose" rel="next">
                      Next Post <span className="ast-right-arrow">→</span>
                    </NavLink>
                  </div>
                </div>
              </nav>
              <div id="comments" className="comments-area"></div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BagsGiving;
