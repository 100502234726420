import { Link } from "react-router-dom";
import "./AboutUs.css";

function AboutUs() {
  return (
    <div>
      <div id="content" className="site-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-1058 page type-page status-publish ast-article-single"
                id="post-1058"
              >
                <header className="entry-header ast-header-without-markup"></header>
                <div className="entry-content clear">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="1058"
                    className="elementor elementor-1058"
                    data-elementor-settings="[]"
                  >
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <section
                          className="elementor-element elementor-element-0eab6a0 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="0eab6a0"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-background-overlay"></div>
                          <div className="elementor-container elementor-column-gap-wide">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-9a808b8 elementor-column elementor-col-100 elementor-top-column"
                                data-id="9a808b8"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-1d9be91 elementor-widget elementor-widget-spacer"
                                      data-id="1d9be91"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-da4446b elementor-widget elementor-widget-heading"
                                      data-id="da4446b"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h3 className="elementor-heading-title elementor-size-default">
                                          About Us
                                        </h3>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-03a77d8 elementor-widget elementor-widget-divider"
                                      data-id="03a77d8"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-ac575ee elementor-widget elementor-widget-heading"
                                      data-id="ac575ee"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-heading-title elementor-size-default">
                                          Across the globe, we are united in our
                                          dedication to children's rights​
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-c697be1 elementor-widget elementor-widget-spacer"
                                      data-id="c697be1"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-cc569a8 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="cc569a8"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-background-overlay"></div>
                          <div className="elementor-container elementor-column-gap-wide">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-74db96f elementor-column elementor-col-100 elementor-top-column"
                                data-id="74db96f"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-a691743 elementor-widget elementor-widget-spacer"
                                      data-id="a691743"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-0d3397b elementor-widget elementor-widget-heading"
                                      data-id="0d3397b"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          OUR BEGININGS​
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-d5d0e8f elementor-widget elementor-widget-divider"
                                      data-id="d5d0e8f"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-element elementor-element-62c88d7 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="62c88d7"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-no">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-82c5286 elementor-column elementor-col-50 elementor-inner-column"
                                            data-id="82c5286"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-ce39b65 elementor-widget elementor-widget-spacer"
                                                  data-id="ce39b65"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-dd10652 elementor-widget elementor-widget-text-editor"
                                                  data-id="dd10652"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        The Roland Torres
                                                        Votacion Foundation,
                                                        Inc., is the heart and <b>
                                                          Corporate Social
                                                          Responsibility
                                                        </b> of <b>Dev Partners</b> in establishing a
                                                        sustainable community.
                                                        Inspired by the
                                                        teachings and doctrines
                                                        of St. John Bosco, the
                                                        RTVFI aims to make a
                                                        difference to the lives
                                                        of the unprivileged
                                                        youth and students
                                                        especially “the lost,
                                                        the least, and the
                                                        last.”
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-b21a9f4 elementor-widget elementor-widget-text-editor"
                                                  data-id="b21a9f4"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        <span className="focusing">
                                                          Focusing in one of the
                                                          remote barangays of
                                                          Arakan in North
                                                          Cotabato, Island of
                                                          Mindanao, RTFVI
                                                          continues to pursue
                                                          the mission to help
                                                          people — help
                                                          themselves through
                                                          education and to give
                                                          opportunities that
                                                          could help shape and
                                                          build their future.
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-173eb4f elementor-align-left elementor-widget__width-inherit elementor-mobile-align-center elementor-widget elementor-widget-button"
                                                  data-id="173eb4f"
                                                  data-element_type="widget"
                                                  data-widget_type="button.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-button-wrapper">
                                                      <Link
                                                        to="/support-us"
                                                        className="elementor-button-link elementor-button elementor-size-xs"
                                                        role="button"
                                                      >
                                                        <span className="elementor-button-content-wrapper">
                                                          <span className="elementor-button-text">
                                                            learn more
                                                          </span>
                                                        </span>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-94501a6 elementor-widget elementor-widget-spacer"
                                                  data-id="94501a6"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-86d6f60 elementor-column elementor-col-50 elementor-inner-column"
                                            data-id="86d6f60"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-background-overlay"></div>
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-ba24835 elementor-widget elementor-widget-spacer"
                                                  data-id="ba24835"
                                                  data-element_type="widget"
                                                  data-widget_type="spacer.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                      <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <div
                                      className="elementor-element elementor-element-96ed475 elementor-widget elementor-widget-spacer"
                                      data-id="96ed475"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-ff5421a elementor-widget elementor-widget-spacer"
                                      data-id="ff5421a"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-a4714c0 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="a4714c0"
                          data-element_type="section"
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-f02d93e elementor-column elementor-col-100 elementor-top-column"
                                data-id="f02d93e"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-0f1a0eb elementor-widget elementor-widget-spacer"
                                      data-id="0f1a0eb"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-9a1f72d elementor-widget elementor-widget-heading"
                                      data-id="9a1f72d"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                          OUR BEGININGS​
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-06c516a elementor-widget elementor-widget-divider"
                                      data-id="06c516a"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <section
                                      className="elementor-element elementor-element-8d12cde elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="8d12cde"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-119db74 elementor-column elementor-col-50 elementor-inner-column"
                                            data-id="119db74"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-4384ff4 elementor-widget elementor-widget-image"
                                                  data-id="4384ff4"
                                                  data-element_type="widget"
                                                  data-widget_type="image.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-image">
                                                      <img
                                                        width="1024"
                                                        height="767"
                                                        src="assets/images/20191130_101748-1024x767-1.jpg"
                                                        className="attachment-large size-large"
                                                        alt=""
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-3636875 elementor-column elementor-col-50 elementor-inner-column"
                                            data-id="3636875"
                                            data-element_type="column"
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-73b1b85 elementor-widget elementor-widget-heading"
                                                  data-id="73b1b85"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">
                                                      01.
                                                    </h5>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-765f3a4 elementor-widget elementor-widget-heading"
                                                  data-id="765f3a4"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h3 className="elementor-heading-title elementor-size-default">
                                                      OUR MISSION​
                                                    </h3>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-9374cea elementor-widget elementor-widget-text-editor"
                                                  data-id="9374cea"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        We partner with the
                                                        community and
                                                        stakeholders to provide
                                                        free educational
                                                        assistance to our chosen
                                                        scholars from pre-school
                                                        to college, where the
                                                        possibilities,
                                                        opportunities and dreams
                                                        are all in the same
                                                        ground through our
                                                        Education to Employment
                                                        (E2E) program.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-f869de8 elementor-widget elementor-widget-heading"
                                                  data-id="f869de8"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h5 className="elementor-heading-title elementor-size-default">
                                                      02.
                                                    </h5>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-b3039a8 elementor-widget elementor-widget-heading"
                                                  data-id="b3039a8"
                                                  data-element_type="widget"
                                                  data-widget_type="heading.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <h3 className="elementor-heading-title elementor-size-default">
                                                      OUR VISION​
                                                    </h3>
                                                  </div>
                                                </div>
                                                <div
                                                  className="elementor-element elementor-element-79cab85 elementor-widget elementor-widget-text-editor"
                                                  data-id="79cab85"
                                                  data-element_type="widget"
                                                  data-widget_type="text-editor.default"
                                                >
                                                  <div className="elementor-widget-container">
                                                    <div className="elementor-text-editor elementor-clearfix">
                                                      <p>
                                                        We envision a country
                                                        where the
                                                        underprivileged youth
                                                        and students, especially
                                                        the Indigenous Peoples
                                                        of the Philippines,
                                                        enjoy a free education
                                                        that propels them toward
                                                        the sustainable and
                                                        transformative lives
                                                        they deserve.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <div
                                      className="elementor-element elementor-element-6eba521 elementor-widget elementor-widget-spacer"
                                      data-id="6eba521"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          className="elementor-element elementor-element-4c106bb elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="4c106bb"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-ff1882b elementor-column elementor-col-100 elementor-top-column"
                                data-id="ff1882b"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-43048b7 elementor-widget elementor-widget-spacer"
                                      data-id="43048b7"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-4a96e2f elementor-view-default elementor-widget elementor-widget-icon"
                                      data-id="4a96e2f"
                                      data-element_type="widget"
                                      data-widget_type="icon.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-icon-wrapper">
                                          <div className="elementor-icon">
                                            <i
                                              aria-hidden="true"
                                              className="fas fa-quote-right"
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-543b8f5 elementor-widget elementor-widget-text-editor"
                                      data-id="543b8f5"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <p>
                                            “The school was not the end,
                                            <br />
                                            It was rather the instrumental means
                                            for improving the way of life.”
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-0a817e3 elementor-widget elementor-widget-text-editor"
                                      data-id="0a817e3"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <p>&#8211; St. John Bosco</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-7c88aa5 elementor-widget elementor-widget-spacer"
                                      data-id="7c88aa5"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <section
                          className="elementor-element elementor-element-dfa316c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
                          data-id="dfa316c"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div
                                className="elementor-element elementor-element-d05063b elementor-column elementor-col-100 elementor-top-column"
                                data-id="d05063b"
                                data-element_type="column"
                              >
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div
                                      className="elementor-element elementor-element-d42ae5b elementor-widget elementor-widget-spacer"
                                      data-id="d42ae5b"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-5b6ef6b elementor-widget elementor-widget-divider"
                                      data-id="5b6ef6b"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-cbbcc08 elementor-widget elementor-widget-heading"
                                      data-id="cbbcc08"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <span className="elementor-heading-title elementor-size-large">
                                          You can help us make difference
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-69cb9bf elementor-widget elementor-widget-heading"
                                      data-id="69cb9bf"
                                      data-element_type="widget"
                                      data-widget_type="heading.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <span className="elementor-heading-title elementor-size-large">
                                          <span className="support-us">
                                            SUPPORT US AND CHANGE THE COURSE OF
                                            CHILD'S LIFE TODAY
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-a3bb1e0 elementor-widget elementor-widget-button"
                                      data-id="a3bb1e0"
                                      data-element_type="widget"
                                      data-widget_type="button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                          <Link
                                            to="/donate"
                                            className="elementor-button-link elementor-button elementor-size-lg"
                                            role="button"
                                          >
                                            <span className="elementor-button-content-wrapper">
                                              <span className="elementor-button-icon elementor-align-icon-right">
                                                <i
                                                  aria-hidden="true"
                                                  className="fab fa-paypal"
                                                ></i>
                                              </span>
                                              <span className="elementor-button-text">
                                                donate now
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-9fb2caa elementor-widget elementor-widget-spacer"
                                      data-id="9fb2caa"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
